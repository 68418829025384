import { Accessors, DynamicDecl, RuleDecl } from "vue/types/options";
import { required, sameAs } from "vuelidate/lib/validators";
import { Computed } from "vuex";
import { ResetPasswordForm } from "./component";

export const validations: DynamicDecl = function (
  this: ResetPasswordForm
): RuleDecl {
  const rules: { [name: string]: RuleDecl } = {
    newPassword: { required },
    newPasswordRepeated: { required, repeated: sameAs("newPassword") },
  };

  return rules;
};

export const errorMessages: Accessors<Computed> = {
  newPasswordErrors(this: ResetPasswordForm) {
    const messages: string[] = [];

    if (this.$v.newPassword && this.$v.newPassword.$error) {
      if (!this.$v.newPassword.required) {
        messages.push("Ett lösenord måste fyllas i.");
      }
    }

    return messages;
  },
  newPasswordRepeatedErrors(this: ResetPasswordForm) {
    const messages: string[] = [];

    if (this.$v.newPasswordRepeated && this.$v.newPasswordRepeated.$error) {
      if (!this.$v.newPasswordRepeated.required) {
        messages.push("Lösenordet måste upprepas.");
      }
      if (!this.$v.newPasswordRepeated.repeated) {
        messages.push("Lösenorden matchar inte.");
      }
    }

    return messages;
  },
};
