/**
 * ASCII to Unicode (decode Base64 to original data)
 *
 * @param base64 - Base64 string
 *
 * @return Unicode string.
 *
 * @see Source https://base64.guru/developers/javascript/examples/unicode-strings
 */
export const atou = (base64: string): string => {
  return decodeURIComponent(escape(atob(base64)));
};

// Expose to window.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).atou = atou;

/**
 * Unicode to ASCII (encode data to Base64)
 *
 * @param unicode - Unicode string
 *
 * @return Base64 string.
 *
 * @see Source https://base64.guru/developers/javascript/examples/unicode-strings
 */
export const utoa = (unicode: string): string => {
  return btoa(unescape(encodeURIComponent(unicode)));
};

// Expose to window.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).utoa = utoa;
