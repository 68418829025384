import "./style/main.scss"; // There MUST be an empty line after this for the fonts to work.

import log from "loglevel";
import Vue from "vue";
import "./lib/VueFilters";
import { auth, router, store, vuetify } from "./plugins";

/* Redirect from 127.x.x.x to localhost
  to fix links to dev server in vite.
  IP addresses don't work with the ID server. */
if (location.hostname.startsWith("127.")) {
  location.hostname = "localhost";
} else {
  if (process.env.NODE_ENV === "production") {
    log.disableAll();
  } else {
    log.setLevel("info");
  }

  Vue.config.productionTip = false;

  const init = async () => {
    // Wait for auth plugin to be installed.
    await auth();

    // Root app.
    const App = new Vue({
      // Plugins:
      router,
      store,
      vuetify,
      // Life-cycle hooks:
      mounted() {
        this.$nextTick(() => {
          // Remove init event listener.
          document.removeEventListener("DOMContentLoaded", init);
        });
      },
      // Render:
      render: (h) => h("router-view"),
    });

    // Mount the app.
    App.$mount("#app");
  };

  // Wait for DOM to finish loading before mounting.
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", init);
  } else {
    init();
  }
}
