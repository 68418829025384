



































































import { ResetPasswordForm } from "./component";

import AsyncButton from "@/components/AsyncButton.vue";
import TextField from "@/components/Field/Text/component.vue";
import UiConflict from "@/components/Ui/Conflict/component.vue";
import UiSnackbar from "@/components/Ui/Snackbar/component.vue";

export default ResetPasswordForm.extend({
  components: {
    AsyncButton,
    TextField,
    UiConflict,
    UiSnackbar,
  },
});
