import Vue from "vue";
import { Auth } from "./Auth";
import { getAuthUrls } from "./getAuthUrls";

Vue.config.productionTip = false;

export { Auth } from "./Auth";

export const AuthPlugin = async (): Promise<void> => {
  Vue.use(Auth, await getAuthUrls());
};
