import { UiSnackbarContext } from "@/components/Ui/Snackbar/state";
import { Message } from "@/components/Ui/Snackbar/types";
import {
  ConflictRejectedError,
  RejectedCounterError,
  RejectedDuplicateError,
} from "@/lib/Api/errors";
import { isApiError } from "@/lib/Api/types";
import { DomainError } from "@/old-domain/DomainError";
import { Color } from "@/old-domain/enums/Color";
import { GenericUserFriendlyError } from "@/old-domain/errors/GenericUserFriendlyError";
import log from "loglevel";
import { Component, Vue } from "vue-property-decorator";
import { FormValidationError } from "./FormMixin";

/**
 * Mixin for error and conflict handling.
 *
 * @remarks
 * Include this mixin if your component needs error of conflict handling.
 */
@Component
export class ErrorMixin extends Vue {
  /** Add snackbar message. */
  get addSnackbarMessage(): (payload: string | Message) => Promise<void> {
    return UiSnackbarContext.actions.addMessage;
  }

  /**
   * Log the error to the console and show an ambiguous message to the user.
   *
   * @param error An error.
   */
  displayError(error?: unknown, noLog = false, noUserMessage = false): void {
    if (
      error instanceof ConflictRejectedError ||
      error instanceof FormValidationError ||
      error instanceof RejectedCounterError ||
      error instanceof RejectedDuplicateError
    ) {
      return;
    }

    let uiNoticeSent = false;

    if (isApiError(error)) {
      const messages: string[] = [];

      for (const message of error.errors) {
        messages.push(message.message);
      }

      if (!noLog) log.error(messages);
    } else if (error instanceof DomainError) {
      if (!noLog) log.error(error.toNativeError());

      if (!noUserMessage) {
        const userMessage = error.toString(true);
        if (userMessage) {
          if (error instanceof GenericUserFriendlyError) {
            // Set message color based on error severity.
            let color = Color.Error;
            switch (error.severity) {
              case "success":
                color = Color.Success;
                break;
              case "warning":
                color = Color.Warning;
                break;
              case "info":
                color = Color.Info;
                break;
            }

            this.addSnackbarMessage({ color, text: userMessage });
          } else {
            this.addSnackbarMessage({
              color: Color.RedDarken4,
              text: userMessage,
              timeout: -1,
            });
          }

          uiNoticeSent = true;
        }
      }
    } else if (error) {
      // Not falsy.
      if (!noLog) log.error(error);
    } else {
      if (!noLog) log.error("Something went wrong.");
    }

    if (!noUserMessage) {
      const isErrorPage = /^Error/.test(this.$route.name ?? "");

      if (!uiNoticeSent && !isErrorPage) {
        this.addSnackbarMessage("Någonting gick fel.");
      }
    }
  }
}
