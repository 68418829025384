import { isNone, isSome, Option } from "fp-ts/lib/Option";
import { AssertionError } from "../errors/AssertionError";
import { Assertion } from "../types/Assertion";

/**
 * Asserts that the value is of type `Option<unknown>`.
 *
 * @param value The value to assert.
 * @param assertion Assertion function.
 *
 * @returns Whether the value is of type `Option<unknown>`.
 */
export const assertIsOption: Assertion<Option<unknown>> = (
  value: unknown
): asserts value is Option<unknown> => {
  if (
    typeof value === "object" &&
    value != null &&
    "_tag" in value &&
    typeof (value as Record<"_tag", unknown>)._tag === "string" &&
    ["None", "Some"].includes((value as Record<"_tag", string>)._tag)
  ) {
    // Value is an Option.
    const option = value as Option<unknown>;

    if (isNone(option) || isSome(option)) {
      return;
    }
  }

  throw new AssertionError("*value* is not an `Option`.");
};

/**
 * Asserts that the value is of type `Option<T>`.
 *
 * @param value The value to assert.
 * @param assertion Assertion function.
 *
 * @returns Whether the value is of type `Option<T>`.
 */
export const assertIsOptionOf = <T>(
  value: unknown,
  assertion: Assertion<T>
): asserts value is Option<T> => {
  if (
    typeof value === "object" &&
    value != null &&
    "_tag" in value &&
    typeof (value as Record<"_tag", unknown>)._tag === "string" &&
    ["None", "Some"].includes((value as Record<"_tag", string>)._tag)
  ) {
    // Value is an Option.
    const option = value as Option<unknown>;

    if (isNone(option)) {
      return;
    } else if (isSome(option)) {
      assertion(option.value);
      return;
    }
  }

  throw new AssertionError("*value* is not an `Option`.");
};
