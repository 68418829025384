import { registerModule } from "@/plugins/Store";
import {
  Actions,
  Getters,
  Module as VuexModule,
  Mutations,
} from "vuex-smart-module";

class ModuleState {}

class ModuleGetters extends Getters<ModuleState> {}

class ModuleMutations extends Mutations<ModuleState> {}

class ModuleActions extends Actions<
  ModuleState,
  ModuleGetters,
  ModuleMutations,
  ModuleActions
> {}

const Module = new VuexModule({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const UiContext = registerModule("Ui", Module);
