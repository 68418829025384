import { Settings } from "@/components/View/Agent/Settings/types/Settings";
import { Api } from "@/lib/Api";
import { registerModule } from "@/plugins/Store";
import { isUuid, Uuid } from "@/types/Brands/Uuid";
import { HttpMethod } from "@/types/Enums/HttpMethod";
import { HttpStatusCode } from "@/types/Enums/HttpStatusCode";
import { User as OidcUser } from "oidc-client";
import {
  Actions,
  Getters,
  Module as VuexModule,
  Mutations,
} from "vuex-smart-module";
import { Router } from "../Router";
import { NoUserFoundError } from "./errors";
import { User, UserRole, UserType } from "./types";

class ModuleState {
  user?: User;
  settings?: Settings<UserType>;
}

class ModuleGetters extends Getters<ModuleState> {
  /** User ID */
  get userId() {
    if (this.state.user?.id) {
      return this.state.user.id;
    }

    throw new Error("User Id was not set.");
  }

  /** Users full name. @deprecated */
  get fullName() {
    return this.state.user
      ? (this.state.user.givenName + " " + this.state.user.familyName).trim()
      : "";
  }

  /** Users initials. @deprecated */
  get initials() {
    return this.state.user
      ? (
          (this.state.user.givenName.trim()[0] || "") +
          (this.state.user.familyName.trim()[0] || "")
        ).trim()
      : "";
  }

  /** User roles. */
  get roles() {
    return this.state.user?.roles || [];
  }

  /** User type. */
  get type() {
    switch (true) {
      case this.getters.isAdmin:
        return UserType.Admin;
      case this.getters.isAgent:
        return UserType.Agent;
      case this.getters.isCustomer:
        return UserType.Customer;
      case this.getters.isResource:
        return UserType.Resource;
    }

    throw new NoUserFoundError();
  }

  get tokenType() {
    if (this.state.user?.tokenType) {
      return this.state.user.tokenType;
    }

    throw new Error("Token type was not set.");
  }

  get accessToken() {
    if (this.state.user?.accessToken) {
      return this.state.user.accessToken;
    }

    throw new Error("Access Token was not set.");
  }

  /** User has expired? */
  get hasExpired() {
    if (this.state.user) {
      return this.state.user.expiresAt < Math.floor(Date.now() / 1000);
    }

    return true;
  }

  get isAdmin() {
    return Boolean(
      this.state.user &&
        "adminId" in this.state.user.ids &&
        isUuid(this.state.user.ids.adminId)
    );
  }

  get isAgent() {
    return Boolean(
      this.state.user &&
        "agencyAgentId" in this.state.user.ids &&
        isUuid(this.state.user.ids.agencyAgentId)
    );
  }

  get isAgentAdmin() {
    return Boolean(
      this.state.user &&
        "agencyAgentId" in this.state.user.ids &&
        isUuid(this.state.user.ids.agencyAgentId) &&
        this.state.user.roles.includes(UserRole.Administrator)
    );
  }

  get isCustomer() {
    return Boolean(
      this.state.user &&
        "agencyCustomerAgentId" in this.state.user.ids &&
        isUuid(this.state.user.ids.agencyCustomerAgentId)
    );
  }

  get isCustomerAdmin() {
    return Boolean(
      this.state.user &&
        "agencyCustomerAgentId" in this.state.user.ids &&
        isUuid(this.state.user.ids.agencyCustomerAgentId) &&
        this.state.user.roles.includes(UserRole.CustomerAdmin)
    );
  }

  get isResource() {
    return Boolean(
      this.state.user &&
        "resourceId" in this.state.user.ids &&
        isUuid(this.state.user.ids.resourceId)
    );
  }

  get isInterpreter() {
    return Boolean(
      this.state.user &&
        "resourceId" in this.state.user.ids &&
        isUuid(this.state.user.ids.resourceId) &&
        this.state.user.roles.includes(UserRole.Interpreter)
    );
  }

  get isContractor() {
    return Boolean(
      this.state.user &&
        "resourceId" in this.state.user.ids &&
        isUuid(this.state.user.ids.resourceId) &&
        this.state.user.roles.includes(UserRole.Contractor)
    );
  }

  // IDs:
  /** @deprecated */
  get adminId() {
    if (this.state.user?.ids.adminId) {
      return this.state.user.ids.adminId;
    }

    throw new Error("Admin ID was not set.");
  }

  /** @deprecated */
  get agencyAgentId() {
    if (this.state.user?.ids.agencyAgentId) {
      return this.state.user.ids.agencyAgentId;
    }

    throw new Error("Agency Agent Id was not set.");
  }

  /** @deprecated */
  get agencyCustomerAgentId() {
    if (this.state.user?.ids.agencyCustomerAgentId) {
      return this.state.user.ids.agencyCustomerAgentId;
    }

    throw new Error("Agency Customer Agent ID was not set.");
  }

  /** @deprecated */
  get agencyCustomerId() {
    if (this.state.user?.ids.agencyCustomerId) {
      return this.state.user.ids.agencyCustomerId;
    }

    throw new Error("Agency Customer ID was not set.");
  }

  /** @deprecated */
  get agencyId() {
    if (this.state.user?.ids.agencyId) {
      return this.state.user.ids.agencyId;
    }

    throw new Error("Agency ID was not set.");
  }

  /** @deprecated */
  get resourceId() {
    if (this.state.user?.ids.resourceId) {
      return this.state.user.ids.resourceId;
    }

    throw new Error("Resource ID was not set.");
  }

  // Settings:
  /** @deprecated @see `getSetting` in helpers. */
  get getSettings() {
    return async <T = string | number | boolean>(
      key: string,
      collection: keyof Settings<UserType> = "agencies"
    ): Promise<T> => {
      const userSettings = this.state.settings;

      if (userSettings) {
        let settings: { [key: string]: boolean | number | string } = {};

        if (collection === "agencies") {
          const agencyId = this.getters.agencyId;
          const agenciesCollection = userSettings.agencies;

          if (agenciesCollection) {
            let notFound = true;

            if (agencyId in agenciesCollection) {
              const agencySettings = agenciesCollection[agencyId];

              if (agencySettings) {
                settings = agencySettings as Record<
                  string,
                  boolean | number | string
                >;
                notFound = false;
              }
            }

            if (notFound) {
              throw new Error("Settings for Agency ID not found.");
            }
          } else {
            throw new Error("User does not have agency settings.");
          }
        } else {
          settings = userSettings[collection] as typeof settings;
        }

        if (key in settings) {
          // Force the type to match.
          return settings[key] as unknown as T;
        }

        throw new Error(
          `Failed to find setting with name "${key}" under collection "${collection}".`
        );
      }

      throw new Error("Settings are not set.");
    };
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  setUser(user: User) {
    this.state.user = user;
  }

  unsetUser() {
    this.state.user = undefined;
    this.state.settings = undefined;
  }

  setSettings(settings: Settings<UserType>) {
    this.state.settings = settings;
  }
}

class ModuleActions extends Actions<
  ModuleState,
  ModuleGetters,
  ModuleMutations,
  ModuleActions
> {
  async setUser(oidcUser: OidcUser) {
    if (oidcUser.expired) {
      await this.actions.unsetUser();
      await Router.push({ name: "SignOut" });
      return;
    }

    const ids: Record<string, Uuid> = {};

    for (const key of Object.keys(oidcUser.profile)) {
      if (/_id$/.test(key) && isUuid(oidcUser.profile[key])) {
        // k[1] will always exist.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let camelKey = key.replace(/_([a-z])/gi, (k) => k[1]!.toUpperCase());

        // Fix agent and customer ids.
        if (/^(agent|customer)/.test(camelKey)) {
          camelKey =
            "agency" + camelKey.substr(0, 1).toUpperCase() + camelKey.substr(1);
        }

        ids[camelKey] = oidcUser.profile[key];
      }
    }

    const common = {
      accessToken: oidcUser.access_token,
      expiresAt: oidcUser.expires_at,
      id: oidcUser.profile.sub as Uuid,
      ids,
      roles:
        typeof oidcUser.profile.role === "string"
          ? [oidcUser.profile.role]
          : oidcUser.profile.role || [],
      tokenType: oidcUser.token_type,
    };

    const user = {
      familyName: (oidcUser.profile.family_name || "").trim(),
      givenName: (oidcUser.profile.given_name || "").trim(),
      ...common,
    };

    // Set user.
    this.mutations.setUser(user);
    if (!this.state.settings) {
      // Get settings.
      await this.actions.getAndSetSettings();
    }
  }

  async unsetUser() {
    sessionStorage.clear();

    // Remove OIDC keys from local storage.
    Object.entries(localStorage)
      .map((keyValue) => keyValue[0])
      .filter((key) => key.substring(0, 4) === "oidc")
      .map((key) => localStorage.removeItem(key));

    this.mutations.unsetUser();
  }

  async getAndSetSettings(): Promise<void> {
    if (
      this.getters.isAgent ||
      this.getters.isCustomer ||
      this.getters.isResource
    ) {
      const response = await Api<Settings<UserType>>(
        "settings",
        HttpMethod.GET,
        {},
        false,
        [HttpStatusCode.UNAUTHORIZED]
      );

      if (response.code === HttpStatusCode.OK && response.data) {
        this.mutations.setSettings(response.data);
        return;
      } else if (response.code === HttpStatusCode.UNAUTHORIZED) {
        await Router.push({ name: "SignOut" });
        return;
      }

      throw new Error("Failed to get settings.");
    }
  }
}

const Module = new VuexModule({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const UserContext = registerModule("User", Module);
