import { Immutable } from "@/old-domain/types/Immutable";
import { utoa } from "../UnicodeBase64";
import { ApiRequest } from "./types";

export const getParams = (
  request: ApiRequest | Immutable<ApiRequest>
): Record<string, unknown> => {
  const params: Record<string, unknown> = {};

  if (request.query) {
    Object.assign(params, request.query);
  }
  if (request.filter) {
    Object.assign(params, request.filter);
  }
  if (request.embed) {
    params.embed = request.embed.join(",");
  }
  if (request.fields) {
    params.fields = request.fields.join(",");
  }
  if (request.limit) {
    params.limit = request.limit;
  }
  if (request.offset) {
    params.offset = request.offset;
  }
  if (request.search && request.search.phrases.length > 0) {
    params.search = utoa(JSON.stringify(request.search));
  }
  if (request.sort) {
    let sort = "";

    for (const [key, value] of request.sort) {
      sort += "," + (value ? "" : "-") + key;
    }

    params.sort = sort.substring(1);
  }

  return params;
};
