/**
 * Get the URL to the API.
 */
export const getApiUrl = async function (): Promise<URL> {
  /** Regex for localhost and all loopback ip-addresses. */
  const localhostRegex =
    /^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*:)*?:?0*1$/i;

  /** Local storage key for development API server url. */
  const devIdServerUrlKey = "DEV_api-server-url";

  if (localhostRegex.test(location.hostname)) {
    // Get Dev Server URL.
    const devServerUrl = localStorage.getItem(devIdServerUrlKey);

    if (typeof devServerUrl === "string" && devServerUrl.length > 0) {
      /** If the url is in local storage we will use it. */
      return new URL(devServerUrl);
    }

    let apiUrl: URL;

    /**
     * For localhost we need to check if a local API is running.
     * If it is, we use it. Otherwise we use the edge API server.
     */
    try {
      await fetch(`https://${location.hostname}:44369/`, { method: "OPTIONS" });
      apiUrl = new URL(`https://${location.hostname}:44369`);
    } catch (error: unknown) {
      apiUrl = new URL("https://api-edge-taqs.oadata.se");
    }

    // Save Dev Server URL.
    localStorage.setItem(devIdServerUrlKey, apiUrl.toString());

    return apiUrl;
  } else if (/\..+\..+/.test(location.hostname)) {
    /** For subdomains we use a sibling subdomain with the prefix "api-". */
    return new URL(`https://api-${location.host}`);
  }

  /** On domains we use the "api" subdomain. */
  return new URL(`https://api.${location.host}`);
};

/**
 * Get the URL to the API synchronously based on local storage.
 *
 * @remarks
 * Should always return a URL object as long as the application has initialized.
 */
export const getApiUrlSync = function (): URL | undefined {
  /** Regex for localhost and all loopback ip-addresses. */
  const localhostRegex =
    /^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*:)*?:?0*1$/i;

  /** Local storage key for development API server url. */
  const devIdServerUrlKey = "DEV_api-server-url";

  if (localhostRegex.test(location.hostname)) {
    // Get Dev Server URL.
    const devServerUrl = localStorage.getItem(devIdServerUrlKey);

    if (typeof devServerUrl === "string" && devServerUrl.length > 0) {
      /** If the url is in local storage we will use it. */
      return new URL(devServerUrl);
    } else {
      return;
    }
  } else if (/\..+\..+/.test(location.hostname)) {
    /** For subdomains we use a sibling subdomain with the prefix "api-". */
    return new URL(`https://api-${location.host}`);
  }

  /** On domains we use the "api" subdomain. */
  return new URL(`https://api.${location.host}`);
};
