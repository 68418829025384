import Vue from "vue";
import Vuex from "vuex";
import {
  Context,
  createStore,
  Module as VuexModule,
  registerModule as registerVuexModule,
  unregisterModule as unregisterVuexModule,
} from "vuex-smart-module";

Vue.use(Vuex);

const root = new VuexModule();

export const Store = createStore(root, {
  strict: process.env.NODE_ENV !== "production",
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModuleInstance = VuexModule<any, any, any, any, any>;

/**
 * Registers a module with the Store.
 *
 * @param name Name to register under.
 * @param module Module to register.
 */
export const registerModule = <T extends ModuleInstance>(
  name: string,
  vuexModule: T
): Context<T> => {
  if (Store.hasModule(name.split("/"))) {
    unregisterVuexModule(Store, vuexModule);
  }

  registerVuexModule(Store, name.split("/"), name + "/", vuexModule);

  return vuexModule.context(Store);
};
