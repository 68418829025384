import {
  assertIsOption,
  assertIsOptionOf,
} from "@/old-domain/assertions/assertIsOption";
import { AssertionError } from "@/old-domain/errors/AssertionError";
import { InvalidArgumentError } from "@/old-domain/errors/InvalidArgumentError";
import { Assertion } from "@/old-domain/types/Assertion";
import { Constructor } from "@/old-domain/types/Constructor";
import { Immutable, Mutable } from "@/old-domain/types/Immutable";
import { JsonObject } from "@/old-domain/types/Json";
import { Nullable } from "@/old-domain/types/Nullable";
import { Predicate } from "@/old-domain/types/Predicate";
import { ValueType } from "@/old-domain/ValueType";
import { BoundedString } from "@/old-domain/valueTypes/BoundedString";
import { Uuid } from "@/old-domain/valueTypes/Uuid";
import { UserContext } from "@/plugins/Auth/state";
import { isUuid as isUuidBrand, Uuid as UuidBrand } from "@/types/Brands/Uuid";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { isSome, Option } from "fp-ts/lib/Option";
import { Ord } from "fp-ts/lib/Ord";
import { Ordering } from "fp-ts/lib/Ordering";
import { cloneDeep } from "lodash";
import { Locale } from "./Locale";

/**
 * Return the column class for a given number of columns.
 *
 * @param columns - Number of columns
 */
export const columnSize = (
  columns: number
):
  | "12"
  | "6"
  | "4"
  | "3"
  | "2_4"
  | "2"
  | "1-71"
  | "1_5"
  | "1_33"
  | "1_2"
  | "1_09"
  | "1"
  | "_92"
  | "_85"
  | "_8"
  | "_75"
  | "_7"
  | "_66"
  | "_63"
  | "_6"
  | "_57"
  | "_54"
  | "_52"
  | "_5" => {
  switch (columns) {
    case 1:
      return "12";
    case 2:
      return "6";
    case 3:
      return "4";
    case 4:
      return "3";
    case 5:
      return "2_4";
    case 6:
      return "2";
    case 7:
      return "1-71";
    case 8:
      return "1_5";
    case 9:
      return "1_33";
    case 10:
      return "1_2";
    case 11:
      return "1_09";
    case 12:
      return "1";
    case 13:
      return "_92";
    case 14:
      return "_85";
    case 15:
      return "_8";
    case 16:
      return "_75";
    case 17:
      return "_7";
    case 18:
      return "_66";
    case 19:
      return "_63";
    case 20:
      return "_6";
    case 21:
      return "_57";
    case 22:
      return "_54";
    case 23:
      return "_52";
    case 24:
      return "_5";

    // Fallback.
    default:
      return "1";
  }
};

/**
 * Triggers a download of a File object.
 *
 * @param file The File object to download.
 */
export const downloadFile = (file: File): void => {
  // Get the object URL.
  const url = URL.createObjectURL(file);

  // Create a hidden anchor element.
  const a = document.createElement("a");
  a.download = file.name;
  a.href = url;
  a.style.setProperty("display", "none");

  // Append it to the body.
  document.body.append(a);

  // Click it.
  a.click();

  // Remove it from the body.
  a.remove();
};

/**
 * Return a string shortened to before the space closest to the max length.
 *
 * @param text The text to shorten.
 * @param maxLength The max length.
 */
export const ellipsis = <
  L extends boolean = never,
  X extends number = never,
  N extends number = never
>(
  text: string | Immutable<BoundedString<L, X, N>>,
  maxLength = 100
): string => {
  // Get text as a string.
  let textAsString = typeof text === "string" ? text : text.toString();
  // Remove line breaks.
  textAsString = textAsString.replace(/[\n\r\u2028\u2029]+/g, " ");

  // Apply ellipsis if necessary and return the shortened string.
  if (textAsString.length > maxLength) {
    const short = textAsString.substr(0, maxLength + 1);
    const lastSpace = short.lastIndexOf(" ");

    if (lastSpace > 0) {
      return short.substr(0, lastSpace - 1) + "…";
    }

    return short.substr(0, maxLength) + "…";
  }

  // Otherwise return the text as a string.
  return textAsString;
};

/**
 * Extracts filename from content disposition header value.
 *
 * @param header Content-Disposition header value.
 */
export const extractFilename = (header: string): string => {
  const matches = header.match(/''([^ ;]*)/);

  if (matches && matches[1]) {
    return decodeURI(matches[1]);
  }

  return "export.pdf";
};

/**
 * Returns the given value with the given percentage added or subtracted.
 *
 * @param value Value to modify
 * @param percent Percent to modify by.
 */
export const percentCalculation = (value: number, percent: number): number => {
  const subtract = percent < 0;
  const decimalPercent = Math.abs(percent) / 100;

  if (subtract) {
    return value - value * decimalPercent;
  }

  return value + value * decimalPercent;
};

/**
 * Sleep for the given amount of time.
 *
 * @param milliseconds Milliseconds to sleep for.
 */
export const sleep = async (milliseconds: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(resolve, milliseconds));

/**
 * Close the dialog box on certain keypresses.
 *
 * @param event Keyboard event
 */
export const isDialogCloseKey = (event: KeyboardEvent): boolean => {
  const closeKeys = ["Enter", "Escape", "Space"];

  if (closeKeys.includes(event.code)) {
    return true;
  }

  return false;
};

/**
 * Type Guard for Async Functions.
 *
 * @param value The value to check.
 */
export const isAsyncFunction = (
  value: unknown
): value is () => Promise<void> => {
  return (
    value instanceof Function && value.constructor.name === "AsyncFunction"
  );
};

/**
 * Use an assertion as a predicate.
 *
 * @param value Value to assert.
 * @param assertion Assertion function.
 */
export const toPredicate = <T>(
  value: unknown,
  assertion: Assertion<T>
): value is T => {
  try {
    assertion(value);
    return true;
  } catch {
    return false;
  }
};

/**
 * Use a predicate as a assertion.
 *
 * @param value Value to assert.
 * @param assertion Assertion function.
 */
export const toAssertion = <T>(
  value: unknown,
  predicate: Predicate<T>
): asserts value is T => {
  if (!predicate(value)) {
    throw new AssertionError("Value failed the predicate.");
  }
};

/**
 * Checks whether a value can be instantiated as a specific value type.
 *
 * @param value Value to check.
 */
export const isValueType = <
  T extends Constructor<ValueType<string | number | bigint>>
>(
  value: unknown,
  valueType: T,
  ...args: unknown[]
): value is InstanceType<T> => {
  try {
    new valueType(value, ...args);
    return true;
  } catch {
    return false;
  }
};

/**
 * Shorthand function to predicate value as `Uuid`.
 *
 * @param value Value to predicate.
 *
 * @returns Whether value is `Uuid`.
 */
export const isUuid = (value: unknown): value is Uuid =>
  toPredicate(value, Uuid.assert);

/**
 * Shorthand function to predicate value as `Option<Uuid>`.
 *
 * @param value Value to predicate.
 *
 * @returns Whether value is `Option<Uuid>`.
 */
export const isOptionUuid = (value: unknown): value is Option<Uuid> => {
  const assertIsOptionUuid: Assertion<Option<Uuid>> = (value) =>
    assertIsOptionOf(value, Uuid.assert);

  return toPredicate(value, assertIsOptionUuid);
};

/**
 * Shorthand function to predicate value as `Option<UuidBrand>`.
 *
 * @param value Value to predicate.
 *
 * @returns Whether value is `Option<UuidBrand>`.
 */
export const isOptionUuidBrand = (
  value: unknown
): value is Option<UuidBrand> => {
  const assertIsUuidBrand: Assertion<UuidBrand> = (value) => {
    if (!isUuidBrand(value))
      throw new AssertionError("*value* is not a `UuidBrand`.");
  };

  const assertIsOptionUuid: Assertion<Option<UuidBrand>> = (value) =>
    assertIsOptionOf(value, assertIsUuidBrand);

  return toPredicate(value, assertIsOptionUuid);
};

/**
 * Shorthand function to predicate value as `Option<unknown>`.
 *
 * @param value Value to predicate.
 *
 * @returns Whether value is `Option<unknown>`.
 */
export const isOption = (value: unknown): value is Option<unknown> => {
  return toPredicate(value, assertIsOption);
};

/**
 * Shorthand function to predicate value as `Option<T>`.
 *
 * @param value Value to predicate.
 * @param predicate Predicate function.
 *
 * @returns Whether value is `Option<T>`.
 */
export const isOptionOf = <T>(
  value: unknown,
  predicate: Predicate<T>
): value is Option<T> => {
  const assertIsOptionOfT: Assertion<Option<T>> = (value) =>
    assertIsOptionOf(value, (value) => toAssertion(value, predicate));

  return toPredicate(value, assertIsOptionOfT);
};

/**
 * Shorthand function to predicate value as `Option<Option<T>>`.
 *
 * @param value Value to predicate.
 * @param predicate Predicate function.
 *
 * @returns Whether value is `Option<Option<T>>`.
 */
export const isDoubleOptionOf = <T>(
  value: unknown,
  predicate: Predicate<T>
): value is Option<Option<T>> => {
  const assertIsDoubleOptionOf: Assertion<Option<Option<T>>> = (value) =>
    assertIsOptionOf(value, (value) =>
      assertIsOptionOf(value, (value) => toAssertion(value, predicate))
    );

  return toPredicate(value, assertIsDoubleOptionOf);
};

export const getFlattenedOptionFromNullable = <T>(
  value: Nullable<T | Option<T>>
): Option<T> =>
  pipe(
    value,
    E.fromNullable(O.none),
    E.filterOrElse(
      (value): value is Option<T> => isOption(value),
      (value) => O.some(value) as Option<T>
    ),
    E.toUnion,
    O.chain((value) => O.fromNullable(value))
  );

/**
 * Get the value of an option as a string.
 *
 * @param option Option to get string from.
 */
export const getString = <T extends ValueType>(
  option: Immutable<Option<T>>
): string => {
  return isSome(option) ? option.value.toString() : "";
};

/**
 * Converts an array into a tuple of one or more values.
 *
 * @param array Array containing at least one element.
 */
export const oneOrMore = <T>(array: ReadonlyArray<T>): [T, ...T[]] => {
  const first = array[0];

  if (first) {
    return [first, ...array.slice(1)];
  }

  throw new InvalidArgumentError(
    1,
    "The given array must contain at least one element."
  );
};

/**
 * Clones an immutable object deeply and make it mutable.
 *
 * @param value Immutable object.
 */
export const deepClone = <V extends Immutable<unknown>>(
  value: V
): V extends Immutable<infer T> ? Mutable<T> : never => {
  return cloneDeep(value) as never;
};

/**
 * Get a setting from the user state.
 *
 * @param key - Setting to get.
 * @param fallback - The value to use if the setting was not found.
 * @param collection - Collection to get the setting from.
 *
 * @returns The value of the setting or the fallback value.
 */
export const getSetting = <T, F extends T = T>(
  key: string,
  fallback: F,
  collection: "agencies" | "global" | "user" = "agencies",
  targetAgencyId?: Option<Uuid>
): T => {
  const settings = UserContext.state.settings as JsonObject | undefined;

  if (
    collection === "agencies" &&
    settings &&
    typeof settings.agencies === "object" &&
    settings.agencies != null &&
    !Array.isArray(settings.agencies)
  ) {
    const userAgencyId = UserContext.state.user?.ids.agencyId as
      | string
      | undefined;
    let agencyId: string | undefined = undefined;

    if (targetAgencyId == null) {
      agencyId = userAgencyId;
    } else if (isSome(targetAgencyId)) {
      agencyId = targetAgencyId.value.toString();
    }

    if (agencyId) {
      if (agencyId in settings.agencies) {
        const agencySettings = settings.agencies[agencyId];

        if (
          agencySettings &&
          typeof agencySettings === "object" &&
          agencySettings != null &&
          !Array.isArray(agencySettings) &&
          key in agencySettings
        ) {
          return (agencySettings[key] as T) ?? fallback;
        }
      }
    } else {
      const results: unknown[] = [];

      for (const agencySettings of Object.values(settings.agencies)) {
        if (
          agencySettings &&
          typeof agencySettings === "object" &&
          !Array.isArray(agencySettings) &&
          agencySettings != null &&
          key in agencySettings
        ) {
          const value = agencySettings[key];
          if (value) {
            results.push(value);
          }
        }
      }

      return results as unknown as T;
    }
  } else if (settings && collection !== "agencies") {
    const collectionSettings = settings[collection];

    if (
      collectionSettings &&
      typeof collectionSettings === "object" &&
      collectionSettings != null &&
      !Array.isArray(collectionSettings) &&
      key in collectionSettings
    ) {
      return (collectionSettings[key] as T) ?? fallback;
    }
  }

  return fallback;
};

/**
 * Replace all values in one instance of an object with the values from another.
 *
 * @param target - The target instance.
 * @param source - The source instance.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const replaceAll = <T extends {}>(target: T, source: T): void => {
  for (const keyString of Object.keys(target)) {
    const key = keyString as keyof T & string;
    target[key] = source[key];
  }
};

/** Swedish string Ord. */
export const swedishOrd: Ord<string> = {
  equals: (a, b) => a === b,
  compare: new Intl.Collator(Locale).compare as (
    a: string,
    b: string
  ) => Ordering,
};
