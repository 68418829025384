import { Immutable, Mutable } from "@/old-domain/types/Immutable";
import { HttpStatusCode } from "@/types/Enums/HttpStatusCode";
import { inObject, isArray, isObject } from "@/types/TypeGuard";
import { AxiosError } from "axios";
import { Operation } from "fast-json-patch";

export const isAxiosError = <T = unknown>(
  value: unknown
): value is AxiosError<T> => {
  return (
    value instanceof Error &&
    inObject("isAxiosError", value, "boolean") &&
    value.isAxiosError
  );
};

export type CleanPartial<T, F> = Mutable<{
  [P in keyof T]?: Exclude<T[P], F>;
}>;

interface ApiRequestHeaders {
  Attempt?: "1" | "2" | "3";
  ContentType?: "application/json" | "multipart/form-data";
  Force?: "false" | "true";
}

export interface ApiRequestFilter {
  [property: string]: string | number | null | undefined;
}

export enum ApiRequestSearchOperator {
  Equals = 1,
  NotEquals,
  Like,
  StartsWith,
  GreaterThan,
  GreaterThanOrEquals,
  LessThan,
  LessThanOrEquals,
  In,
  NotIn,
  NotLike,
}

export enum ApiRequestSearchPhraseOperator {
  And = 1,
  Or,
}

export interface ApiRequestSearchPhrase {
  operator: ApiRequestSearchOperator;
  parameter: string;
  value: (string | number | boolean | null | undefined)[];
}

export interface ApiRequestSearch {
  phraseOperator: ApiRequestSearchPhraseOperator;
  phrases: (ApiRequestSearchPhrase | ApiRequestSearch)[];
}

type ApiRequestDataTypes =
  | boolean
  | boolean[]
  | number
  | number[]
  | string
  | string[]
  | FormData
  | Operation[]
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /*
    Record<string, unknown> currently doesn't work like it should.
    Using Record<string, any> as a workaround.
    See: https://github.com/microsoft/TypeScript/issues/15300
  */
  | Record<string, any>
  | Record<string, any>[];
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface ApiRequest<T = ApiRequestDataTypes> {
  counter?: number;
  data?: T;
  embed?: string[];
  fields?: string[];
  filter?: ApiRequestFilter;
  headers?: ApiRequestHeaders;
  ids?: (string | { toString: () => string })[];
  limit?: number;
  meta?: { [key: string]: unknown };
  offset?: number;
  search?: ApiRequestSearch;
  sort?: [string, boolean][];
  query?: Record<string, unknown>;
}

export interface ApiResponseHeaders {
  ContentDisposition?: string;
  ContentType?: string;
  Location?: string;
  TotalCount?: number;
}

export interface ApiResponse<T, R = ApiRequestDataTypes> {
  code?: HttpStatusCode;
  counter?: number;
  data?: T;
  headers?: ApiResponseHeaders;
  requestUrl: string;
  request: ApiRequest<R> | Immutable<ApiRequest<R>>;
}

export interface ApiErrorMessage {
  errorCode: number;
  forceable: boolean;
  message: string;
  type: string;
}

const isApiErrorMessage = (value: unknown): value is ApiErrorMessage => {
  return (
    isObject(value) &&
    inObject("errorCode", value, "number") &&
    inObject("forceable", value, "boolean") &&
    inObject("message", value, "string") &&
    inObject("type", value, "string")
  );
};

export interface ApiError {
  errors: [ApiErrorMessage, ...ApiErrorMessage[]];
}

export const isApiError = (value: unknown): value is ApiError => {
  return (
    isObject(value) && inObject("errors", value, isArray, isApiErrorMessage)
  );
};
