import { getApiUrl } from "@/lib/Api/getApiUrl";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  ILogger,
  LogLevel,
} from "@aspnet/signalr";
import log from "loglevel";
import Vue, { PluginFunction } from "vue";
import { UserType } from "./Auth/types";

export class SignalR {
  //#region Variables

  /** Hub Connection */
  private connection?: HubConnection;

  /** Is Connected Flag */
  get isConnected(): boolean {
    return (
      this.connection != null &&
      this.connection.state === HubConnectionState.Connected
    );
  }

  /** Is Connecting Flag */
  private isConnecting = false;

  //#endregion

  //#region Methods

  async connect(
    userType: Exclude<UserType, UserType.Admin>,
    accessTokenFactory: () => string
  ): Promise<void> {
    if (this.connection) {
      return this.reconnect();
    }

    // Get API URL.
    const apiUrl = await getApiUrl();

    // Get Hub URL.
    const hubUrl = `${apiUrl}v1/hubs/${userType}s`;

    // Options for URL connection.
    const options: IHttpConnectionOptions = {
      accessTokenFactory,
    };

    const logger: ILogger = {
      log: (logLevel, logMessage) => {
        let message = "";

        switch (logLevel) {
          case LogLevel.Critical:
            message += "SignalR Log Critical: ";
            break;
          case LogLevel.Debug:
            message += "SignalR Log Debug: ";
            break;
          case LogLevel.Error:
            message += "SignalR Log Error: ";
            break;
          case LogLevel.Information:
            message += "SignalR Log Info: ";
            break;
          case LogLevel.Trace:
            message += "SignalR Log Trace: ";
            break;
          case LogLevel.Warning:
            message += "SignalR Log Warning: ";
            break;
        }

        message += logMessage;

        switch (logLevel) {
          case LogLevel.Critical:
          case LogLevel.Error:
            log.error(message);
            break;
          case LogLevel.Debug:
          case LogLevel.Information:
          case LogLevel.Trace:
            log.debug(message);
            break;
          case LogLevel.Warning:
            log.warn(message);
            break;
        }

        if (/(fail|error)/i.test(logMessage)) {
          this.errorHandler();
        }
      },
    };

    // Build hub connection.
    this.connection = new HubConnectionBuilder()
      .withUrl(hubUrl, options)
      .configureLogging(logger)
      .build();

    // Reconnect handler.
    this.connection.onclose((error) => {
      this.errorHandler(error);
    });

    // Attempt to start the connection.
    this.startConnection();
  }

  private errorHandler(error?: unknown): void {
    if (error) log.error("SignalR Error: ", error);
    log.info("SignalR: Attempting to reconnect in five seconds.");
    setTimeout(() => {
      this.reconnect();
    }, 5000);
  }

  async reconnect(): Promise<void> {
    if (!this.isConnected && !this.isConnecting) {
      return this.startConnection();
    }
  }

  private async startConnection(): Promise<void> {
    if (this.connection) {
      try {
        this.isConnecting = true;
        await this.connection.start();
        this.isConnecting = false;
      } catch (error: unknown) {
        this.isConnecting = false;
        this.errorHandler(error);
      }
    }
  }

  /**
   * Register method handler.
   *
   * @param name - Method name.
   * @param handler - Method handler.
   */
  on(name: string, handler: (...args: unknown[]) => void): void {
    if (this.connection) {
      // Register the handler.
      this.connection.on(name, handler);
    }
  }

  /**
   * Unregister method handler.
   *
   * @param name - Method name.
   */
  off(name: string, handler?: (...args: unknown[]) => void): void {
    if (this.connection) {
      if (handler) {
        // Unregister the given handler for the method name.
        this.connection.off(name, handler);
      } else {
        // Unregister all handlers for the method name.
        this.connection.off(name);
      }
    }
  }

  /** Invoke method. */
  async invoke(name: string, ...args: unknown[]): Promise<unknown> {
    if (this.connection) {
      // Invoke method.
      return this.connection.invoke(name, ...args);
    }
  }

  //#endregion

  //#region Vue Plugin

  /**
   * Add SignalR to Vue prototype.
   *
   * @param Vue Vue instance.
   */
  static install: PluginFunction<undefined> = function (Vue) {
    Vue.prototype.$signalR = Vue.observable(new SignalR());
  };

  //#endregion
}

Vue.use(SignalR);
