import { Color } from "@/types/Enums/Color";
import { Component, Vue } from "vue-property-decorator";
import { UiConflictContext } from "./state";

@Component
export class UiConflict extends Vue {
  //#region Variables

  /** Card Color */
  get cardColor(): Color {
    return this.stateForceable ? Color.AmberLighten2 : Color.RedLighten3;
  }

  /** Toolbar Color */
  get toolbarColor(): Color {
    return this.stateForceable ? Color.Warning : Color.Error;
  }

  //#endregion

  //#region Methods

  useForce(): void {
    this.stateResolve();
  }

  close(): void {
    this.stateReject();
  }

  //#endregion

  //#region State

  /** Forceable flag. */
  get stateForceable(): boolean {
    return UiConflictContext.state.forceable;
  }

  /** Message */
  get stateMessage(): string {
    return UiConflictContext.state.message;
  }

  /** Reject conflict callback. */
  get stateReject(): (value: void | PromiseLike<void>) => void {
    return UiConflictContext.state.reject;
  }

  /** Resolve conflict callback. */
  get stateResolve(): (value: void | PromiseLike<void>) => void {
    return UiConflictContext.state.resolve;
  }

  /** Show conflict flag. */
  get stateShow(): boolean {
    return UiConflictContext.state.show;
  }

  //#endregion
}
