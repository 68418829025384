import { StaticImplements } from "../decorators/StaticImplements";
import { AssertionError } from "../errors/AssertionError";
import { Assertion } from "../types/Assertion";
import { ValueType, ValueTypeStatic } from "../ValueType";

/** Universally unique identifier. */
@StaticImplements<ValueTypeStatic<Uuid>>()
export class Uuid extends ValueType {
  /**
   * Assets that `value` is an instance of `Uuid`.
   *
   * @param value The value to assert.
   */
  static assert: Assertion<Uuid> = (value) =>
    ValueType.assertInternal(value, Uuid);

  /** Regular expression to test for a valid UUID. */
  private readonly expression = new RegExp(
    "^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$",
    "i"
  );

  /** UUID as a primitive. */
  protected readonly primitiveValue;

  /** UUID as a string. */
  protected readonly stringValue;

  constructor(value: string) {
    // Call the super constructor.
    super();

    // Check that value is a valid UUID.
    if (!this.expression.test(value)) {
      throw new AssertionError("*value* is not a `Uuid`.");
    }

    // Set the primitive value.
    this.primitiveValue = value;

    // Set the string value.
    this.stringValue = value;
  }
}
