import Vue from "vue";
import VuetifyLib from "vuetify/lib";
import sv from "vuetify/src/locale/sv";

Vue.use(VuetifyLib);

export const Vuetify = new VuetifyLib({
  lang: {
    locales: { sv },
    current: "sv",
  },
});
