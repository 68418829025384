import { UserContext } from "@/plugins/Auth/state";
import { uuidRegExp } from "@/types/Brands/Uuid";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const uuid = uuidRegExp.toString().slice(2, -3);

export const ResourceRoutes: RouteConfig[] = [
  {
    path: "/resource",
    component: () =>
      import(/* webpackChunkName: "Ui" */ "@/components/Ui/component.vue"),
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      if (UserContext.state.user) {
        if (UserContext.getters.isResource) {
          next();
        } else {
          next({ name: "CatchAll", params: { 0: to.fullPath } });
        }
      } else {
        sessionStorage.setItem("return-url", to.fullPath);
        next({ name: "App" });
      }
    },
    children: [
      // Profile
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "Resource.Profile" */ "@/components/View/Resource/Profile/View.vue"
          ),
        children: [
          {
            name: "Resource.Profile",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Resource.Profile" */ "@/components/View/Resource/Profile/FormView.vue"
              ),
          },
        ],
      },
      // Assignments
      {
        path: "assignments",
        component: () =>
          import(
            /* webpackChunkName: "Resource.Assignment" */ "@/components/View/Resource/Assignment/View.vue"
          ),
        children: [
          {
            name: "Resource.Assignments",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Resource.Assignment" */ "@/components/View/Resource/Assignment/TableView.vue"
              ),
          },
          {
            name: "Resource.ReportAssignment",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Resource.Assignment" */ "@/components/View/Resource/Assignment/ReportView.vue"
              ),
          },
        ],
      },
      // Feedback
      {
        path: `assignments/:id(${uuid})/feedback`,
        component: () =>
          import(
            /* webpackChunkName: "Resource.Feedback" */ "@/components/View/Resource/Feedback/View.vue"
          ),
        children: [
          {
            name: "Resource.Feedback",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Resource.Feedback" */ "@/components/View/Resource/Feedback/FormView.vue"
              ),
          },
        ],
      },
      // Calendar
      {
        path: "calendar",
        component: () =>
          import(
            /* webpackChunkName: "Resource.Calendar" */ "@/components/View/Resource/Calendar/View.vue"
          ),
        children: [
          {
            name: "Resource.Calendar",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Resource.Calendar" */ "@/components/View/Resource/Calendar/ShowView.vue"
              ),
          },
        ],
      },
      // Salary Details
      {
        path: "salary-details",
        component: () =>
          import(
            /* webpackChunkName: "Resource.SalaryDetails" */ "@/components/View/Resource/SalaryDetails/View.vue"
          ),
        children: [
          {
            name: "Resource.SalaryDetails",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Resource.SalaryDetails" */ "@/components/View/Resource/SalaryDetails/TableView.vue"
              ),
          },
        ],
      },
    ],
  },
];
