import { isMetaData } from "./MetaData";

/** TypeGuard type. */
export type TypeGuard<T = unknown> = (
  value: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...params: any[]
) => value is T;

/** Get the type of T in a TypeGuard. */
type GetTypeGuardType<S> = S extends TypeGuard<infer T> ? T : never;

/**
 * If the key exists in the object, check if it's the correct type.
 *
 * @param key - The key to look for.
 * @param record - The object to check.
 * @param guard - The type to check for.
 */
export const ifInObject = <
  K extends string,
  /*
    Record<string, unknown> currently doesn't work like it should.
    Using Record<string, any> as a workaround.
    See: https://github.com/microsoft/TypeScript/issues/15300
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Record<string, any>,
  T extends "boolean" | "number" | "string" | TypeGuard
>(
  key: K,
  record: R,
  guard: T,
  ...guardParams: unknown[]
): record is R &
  Record<
    K,
    T extends "boolean"
      ? boolean | undefined
      : T extends "number"
      ? number | undefined
      : T extends "string"
      ? string | undefined
      : T extends TypeGuard
      ? GetTypeGuardType<T> | undefined
      : unknown
  > => {
  if (guard instanceof Function) {
    if (!(key in record) || typeof record[key] === "undefined") {
      return true;
    }

    // Purge deleted objects.
    if (isMetaData(record[key]) && record[key].isDeleted) {
      delete record[key];
      return true;
    }

    return guard(record[key], ...guardParams);
  }

  // Narrow the type of guard correctly.
  const type: "boolean" | "number" | "string" = guard;

  return (
    !(key in record) ||
    typeof record[key] === "undefined" ||
    typeof record[key] === type
  );
};

/**
 * Checks if every value in an array are of the guarded type.
 *
 * @param value - The array to check.
 * @param guard - The type to check for.
 */
export const inArray = <T extends "boolean" | "number" | "string" | TypeGuard>(
  value: unknown[],
  guard: T,
  ...guardParams: unknown[]
): value is T extends "boolean"
  ? boolean[]
  : T extends "number"
  ? number[]
  : T extends "string"
  ? string[]
  : T extends TypeGuard
  ? GetTypeGuardType<T>[]
  : unknown[] => {
  if (guard instanceof Function) {
    return value.every((item) => guard(item, ...guardParams));
  }

  // Narrow the type of guard correctly.
  const type: "boolean" | "number" | "string" = guard;

  return value.every((item) => typeof item === type);
};

/**
 * Checks if an object has a key.
 *
 * @remarks
 * Optionally a type check can be done as well.
 *
 * @param key - The key to look for.
 * @param record - The object to check.
 * @param guard - The type to check for.
 */
export const inObject = <
  K extends string,
  /*
    Record<string, unknown> currently doesn't work like it should.
    Using Record<string, any> as a workaround.
    See: https://github.com/microsoft/TypeScript/issues/15300
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Record<string, any>,
  T extends "boolean" | "number" | "string" | TypeGuard
>(
  key: K,
  record: R,
  guard?: T,
  ...guardParams: unknown[]
): record is R &
  Record<
    K,
    T extends "boolean"
      ? boolean
      : T extends "number"
      ? number
      : T extends "string"
      ? string
      : T extends TypeGuard
      ? GetTypeGuardType<T>
      : unknown
  > => {
  if (guard) {
    if (guard instanceof Function) {
      return key in record && guard(record[key], ...guardParams);
    }

    // Narrow the type of guard correctly.
    const type: "boolean" | "number" | "string" = guard;

    return key in record && typeof record[key] === type;
  }

  return key in record;
};

/**
 * Check if `value` is an array.
 *
 * @param value - The value to check.
 */
export const isArray = <T extends "boolean" | "number" | "string" | TypeGuard>(
  value: unknown,
  guard?: T,
  ...guardParams: unknown[]
): value is T extends "boolean"
  ? boolean[]
  : T extends "number"
  ? number[]
  : T extends "string"
  ? string[]
  : T extends TypeGuard
  ? GetTypeGuardType<T>[]
  : unknown[] => {
  if (guard) {
    return Array.isArray(value) && inArray(value, guard, ...guardParams);
  }

  return Array.isArray(value);
};

/**
 * Check if `value` is an object and not null.
 *
 * @param value - The value to check.
 */
export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && value !== null;
};

/**
 * Creates a type guard for a number in a range (inclusive).
 *
 * @remarks
 * Narrowing isn't possible for this type guard.
 *
 * @param min - The minimum value.
 * @param max - The maximum value.
 */
export const rangeGuard =
  (min: number, max: number) =>
  /**
   * Check if `value` is a number in the range.
   *
   * @remarks
   * The type cannot be narrowed.
   *
   * @param value - The value to check.
   */
  (value: unknown): value is number => {
    return typeof value === "number" && value >= min && value <= max;
  };
