/** Error that occurred in the domain. */
export abstract class DomainError {
  /** Native error object. */
  private readonly error: Error;

  /** Error severity. */
  public severity: "error" | "warning" | "success" | "info" = "error";

  /** Name of the error. */
  private name: string;

  /** Error message. */
  private message = "";

  /** User-friendly error message. */
  private userMessage?: string;

  /**
   * @param name Name of the error.
   */
  constructor(name: string) {
    // Create a new native error to hook into.
    this.error = new Error();

    // Set the name of the error.
    this.name = name;
  }

  /**
   * Set the name of the error.
   *
   * @param name Name of the error.
   *
   * @sealed
   */
  setName(name: string): void {
    this.name = name;
  }

  /**
   * Set the error message.
   *
   * @param message Error message.
   */
  setMessage(message: string): void {
    this.message = message;
  }

  /**
   * Set the user-friendly error message.
   *
   * @param userFriendly User-friendly error message.
   */
  setUserMessage(userMessage?: string): void {
    this.userMessage = userMessage;
  }

  /**
   * Get a string representation of the error.
   *
   * @param userFriendly Whether to get a user-friendly error message.
   *
   * @sealed
   */
  toString(userFriendly: true): string | undefined;
  toString(userFriendly?: false): string;
  toString(userFriendly?: boolean): string | undefined {
    if (userFriendly) {
      return this.userMessage;
    }

    this.error.name = this.name;
    this.error.message = this.message;

    return this.error.toString() || undefined;
  }

  /**
   * Get this error as a native error object.
   *
   * @returns Native Error object.
   */
  toNativeError(): Error {
    this.error.name = this.name;
    this.error.message = this.message;

    return this.error;
  }
}
