import { TimeZone } from "@/lib/TimeZone";
import moment, { MomentBuiltinFormat } from "moment-timezone";
import { Brand } from "../Brand";

/** ISO 8601 Timestamp with date, time and timezone. */
export type Timestamp = Brand<string, "Timestamp">;

/**
 * Type guard for ISO 8601 Timestamp with date, time and timezone.
 *
 * @param value Value to test.
 */
export const isTimestamp = (value: unknown): value is Timestamp => {
  const timestampRegExp =
    /^[+-]?\d+-\d\d-\d\dT\d\d:\d\d:\d\d(?:\.\d+)?(?:[+-]\d\d:\d\d|Z)/i;
  return typeof value === "string" && timestampRegExp.test(value);
};

/** ISO 8601 Timestamp with date and timezone. Time is set to the start of the day. */
export type StartOfDay = Brand<Timestamp, "StartOfDay", "__subtype">;

/**
 * Type guard for ISO 8601 Timestamp with date, time and timezone.
 *
 * @param value Value to test.
 */
export const isStartOfDay = (value: unknown): value is StartOfDay => {
  const timestampRegExp =
    /^[+-]?\d+-\d\d-\d\dT00:00:00(?:\.0+)?(?:[+-]\d\d:\d\d|Z)/i;
  return typeof value === "string" && timestampRegExp.test(value);
};

/** ISO 8601 Timestamp with date and timezone. Time is set to the end of the day. */
export type EndOfDay = Brand<Timestamp, "EndOfDay", "__subtype">;

/**
 * Type guard for ISO 8601 Timestamp with date, time and timezone.
 *
 * @param value Value to test.
 */
export const isEndOfDay = (value: unknown): value is EndOfDay => {
  const timestampRegExp =
    /^[+-]?\d+-\d\d-\d\dT23:59:59(?:\.9+)?(?:[+-]\d\d:\d\d|Z)/i;
  return typeof value === "string" && timestampRegExp.test(value);
};

/** ISO 8601 Timestamp with only a date. */
export type DateOnly = Brand<Timestamp, "DateOnly", "__subtype">;

/**
 * Type guard for ISO 8601 Timestamp with only a date.
 *
 * @param value Value to test.
 */
export const isDateOnly = (value: unknown): value is DateOnly => {
  const timestampRegExp = /^[+-]?\d+-\d\d-\d\d$/i;
  return typeof value === "string" && timestampRegExp.test(value);
};

/**
 * Convert ISO 8601 Timestamp with date, time and timezone
 * to a timestamp with only a date.
 *
 * @param value ISO 8601 Timestamp with date, time and timezone.
 */
export const toDateOnly = (value: Timestamp): DateOnly => {
  if (!isTimestamp(value)) {
    throw new Error(`Value "${value}" is not a valid Timestamp.`);
  }

  return value.split("T")[0] as DateOnly;
};

/**
 * Formatter for ISO 8601 Timestamp with date, time and timezone.
 *
 * @param value - ISO 8601 Timestamp with date, time and timezone.
 * @param format - Moment format string.
 */
export const formatTimestamp = (
  value: string | undefined,
  format: string | MomentBuiltinFormat
): string => {
  // Allow some non-timestamp strings.
  if (value && /^\d{4}-\d\d-\d\d$/.test(value) && format === "Y-MM-DD") {
    return value;
  }
  if (value && /^\d\d:\d\d:\d\d$/.test(value) && format === "HH:mm:ss") {
    return value;
  }
  if (value && /^\d\d:\d\d:\d\d$/.test(value) && format === "HH:mm") {
    return value.substr(0, 5);
  }

  if (!isTimestamp(value)) {
    return "";
  }
  const timestamp = moment.tz(value, moment.ISO_8601, true, TimeZone);
  return timestamp.isValid() ? timestamp.format(format) : "";
};

/**
 * Relative time of ISO 8601 Timestamp with date, time and timezone.
 *
 * @param value - ISO 8601 TImestamp with date, time and timezone.
 */
export const relativeTimestamp = (value: string | undefined): string => {
  if (!isTimestamp(value)) {
    return "";
  }
  const timestamp = moment.tz(value, moment.ISO_8601, true, TimeZone);
  return timestamp.isValid() ? timestamp.fromNow() : "";
};
