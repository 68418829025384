import { DomainError } from "../DomainError";

type ArgumentNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

/** An argument was invalid. */
export class InvalidArgumentError extends DomainError {
  /** Ordinals up to tenth in english. */
  private readonly ordinals = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
  ] as const;

  /** The argument number. */
  private argumentNumberInternal: ArgumentNumber;

  /** The original error message. */
  private readonly originalMessage: string;

  /**
   * @param argumentNumber Argument number.
   * @param message Error message.
   */
  constructor(argumentNumber: ArgumentNumber, message: string) {
    // Set the name of the error.
    super("InvalidArgumentError");

    // Set the argument number.
    this.argumentNumberInternal = argumentNumber;

    // Set the original message.
    this.originalMessage = message;

    // Set the error message.
    this.setMessage(this.getOrdinalMessage(message));
  }

  /** The argument number. */
  get argumentNumber(): ArgumentNumber {
    return this.argumentNumberInternal;
  }

  /**
   * Set the argument number.
   *
   * @remarks
   * Also updates the error message and user-friendly error message with the
   * new ordinal.
   *
   * @param argumentNumber Argument number.
   */
  setArgumentNumber(argumentNumber: ArgumentNumber): void {
    // Set the argument number.
    this.argumentNumberInternal = argumentNumber;

    // Update the error message.
    this.setMessage(this.getOrdinalMessage(this.originalMessage));
  }

  /**
   * Get the error message with an ordinal added.
   *
   * @param message Error message.
   */
  private getOrdinalMessage(message: string) {
    const ordinal = this.ordinals[this.argumentNumberInternal - 1];

    return `${message.replace(/\.+$/, "")} as the ${ordinal} argument.`;
  }
}
