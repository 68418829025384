import { Brand } from "../Brand";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import { Country } from "@/lib/Locale";

export type PhoneNumber = Brand<string, "PhoneNumber">;

export const isPhoneNumber = (
  value: unknown,
  strict = true
): value is PhoneNumber => {
  if (!strict && typeof value === "string" && /[0-9 +-]+/.test(value)) {
    // FIXME: Remove this if-statement after seed has been fixed.
    return true;
  } else if (typeof value === "string" && value.length > 0) {
    const parsedNumber = parsePhoneNumberFromString(value, Country);
    return Boolean(parsedNumber?.isValid());
  }
  return false;
};

export const formatPhoneNumber = (value?: string): string => {
  const formatter = new AsYouType(Country);
  return formatter.input(value || "");
};
