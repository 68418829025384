import App from "@/components/App.vue";
import ErrorForbidden from "@/components/Error/Forbidden.vue";
import ErrorNotFound from "@/components/Error/NotFound.vue";
import ErrorServerIsDown from "@/components/Error/ServerIsDown.vue";
import ErrorSomethingWentWrong from "@/components/Error/SomethingWentWrong.vue";
import Loader from "@/components/Loader.vue";
import ResetPasswordForm from "@/components/ResetPasswordForm/component.vue";
import { AdminRoutes } from "@/components/View/Admin/Routes";
import { AgentRoutes } from "@/components/View/Agent/Routes";
import { CustomerRoutes } from "@/components/View/Customer/Routes";
import { ResourceRoutes } from "@/components/View/Resource/Routes";
import Vue from "vue";
import VueRouter from "vue-router";
import { UserContext } from "./Auth/state";

Vue.use(VueRouter);

export const Router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "App",
      path: "/",
      component: App,
    },
    {
      name: "ResetPassword",
      path: "/reset-password",
      component: ResetPasswordForm,
      beforeEnter: (to, from, next) => {
        // Validate recoveryHash.
        if (
          typeof to.query.recoveryHash === "string" &&
          to.query.recoveryHash.length === 48
        ) {
          try {
            atob(to.query.recoveryHash);
            next();
            return;
          } catch (error: unknown) {
            // Ignore.
          }
        }

        next({ name: "App" });
      },
    },
    {
      name: "Home",
      path: "/home",
      redirect: () => {
        switch (true) {
          case UserContext.getters.isAdmin:
            return { name: "Admin.Agencies" };
          case UserContext.getters.isAgent:
            return {
              name: "Agent.AddAssignment",
              query: { formType: "phone" },
            };
          case UserContext.getters.isCustomer:
            return { name: "Customer.Assignments" };
          case UserContext.getters.isResource:
            return { name: "Resource.Assignments" };
          default:
            return { name: "App" };
        }
      },
    },
    {
      name: "Profile",
      path: "/profile",
      redirect: () => {
        switch (true) {
          case UserContext.getters.isAdmin:
            return { name: "Admin.Profile" };
          case UserContext.getters.isAgent:
            return { name: "Agent.Profile" };
          case UserContext.getters.isCustomer:
            return { name: "Customer.Profile" };
          case UserContext.getters.isResource:
            return { name: "Resource.Profile" };
          default:
            return { name: "App" };
        }
      },
    },
    ...AdminRoutes,
    ...AgentRoutes,
    ...CustomerRoutes,
    ...ResourceRoutes,
    {
      name: "ErrorForbidden",
      path: "/error/forbidden",
      component: ErrorForbidden,
    },
    {
      name: "ErrorNotFound",
      path: "/error/not-found",
      component: ErrorNotFound,
    },
    {
      name: "ErrorServerIsDown",
      path: "/error/server-is-down",
      component: ErrorServerIsDown,
    },
    {
      name: "ErrorSomethingWentWrong",
      path: "/error/something-went-wrong",
      component: ErrorSomethingWentWrong,
    },
    {
      name: "Loader",
      path: "/loader",
      component: Loader,
    },
    {
      name: "CatchAll",
      path: "*",
      component: ErrorNotFound,
    },
  ],
});
