





















import Loader from "@/components/Loader.vue";
import { UserContext } from "@/plugins/Auth/state";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: { Loader } })
export default class ErrorForbidden extends Vue {
  get isAgent(): boolean {
    return UserContext.getters.isAgent;
  }
}
