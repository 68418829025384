//import { inObject, isObject } from '@/types/TypeGuard';
import { HttpStatusCode } from "@/types/Enums/HttpStatusCode";
import {
  AxiosInstance,
  AxiosRequestConfig,
  default as AxiosStatic,
} from "axios";

export const getAxios = async (
  apiUrl: URL,
  tokenType?: string,
  accessToken?: string,
  version?: string
): Promise<AxiosInstance> => {
  const headers: AxiosRequestConfig["headers"] = {
    Accept: "application/json",
    Authorization:
      tokenType && accessToken ? tokenType + " " + accessToken : undefined,
  };

  return AxiosStatic.create({
    baseURL: apiUrl.toString() + (version ? version : "v1") + "/",
    headers,
    validateStatus: (status) =>
      [
        HttpStatusCode.OK,
        HttpStatusCode.CREATED,
        HttpStatusCode.NO_CONTENT,
      ].includes(status),
  });
};
