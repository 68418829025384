import { UserContext } from "@/plugins/Auth/state";
import { uuidRegExp } from "@/types/Brands/Uuid";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const uuid = uuidRegExp.toString().slice(2, -3);

export const AgentRoutes: RouteConfig[] = [
  {
    path: "/agent",
    component: () =>
      import(/* webpackChunkName: "Ui" */ "@/components/Ui/component.vue"),
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      if (UserContext.state.user) {
        if (UserContext.getters.isAgent) {
          next();
        } else {
          next({ name: "CatchAll", params: { 0: to.fullPath } });
        }
      } else {
        sessionStorage.setItem("return-url", to.fullPath);
        next({ name: "App" });
      }
    },
    children: [
      // Profile
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Profile" */ "@/components/View/Agent/Profile/View.vue"
          ),
        children: [
          {
            name: "Agent.Profile",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Profile" */ "@/components/View/Agent/Profile/FormView.vue"
              ),
          },
        ],
      },
      // Assignments
      {
        path: "assignments",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Assignment" */ "@/components/View/Agent/Assignment/View.vue"
          ),
        children: [
          {
            name: "Agent.Assignments",
            path: "",
            redirect: {
              name: "Agent.AddAssignment",
              query: { formType: "phone" },
            },
          },
          {
            name: "Agent.AddAssignment",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Assignment" */ "@/components/View/Agent/Assignment/FormView.vue"
              ),
          },
          {
            name: "Agent.EditAssignment",
            path: `:assignmentId(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Assignment" */ "@/components/View/Agent/Assignment/FormView.vue"
              ),
          },
        ],
      },
      // Flagged Assignments
      {
        name: "Agent.FlaggedAssignments",
        path: "flagged-assignments",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Assignment" */ "@/components/View/Agent/Assignment/FlaggedTable.vue"
          ),
      },
      // Assignments with Feedback
      {
        name: "Agent.AssignmentsWithFeedback",
        path: "assignments-with-feedback",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Assignment" */ "@/components/View/Agent/Assignment/WithFeedbackTable.vue"
          ),
      },
      // Last Modified Assignments
      {
        name: "Agent.LastModifiedAssignments",
        path: "last-modified-assignments",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Assignment" */ "@/components/View/Agent/Assignment/LastModifiedTable.vue"
          ),
      },
      // Resources
      {
        path: "resources",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/View.vue"
          ),
        children: [
          {
            name: "Agent.Resources",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/TableView.vue"
              ),
          },
          {
            name: "Agent.AddResource",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/FormView.vue"
              ),
          },
          {
            name: "Agent.EditResource",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/FormView.vue"
              ),
          },
        ],
      },
      {
        path: "external-resources",
        alias: "resources",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/View.vue"
          ),
        children: [
          {
            path: "",
            redirect: "../resources",
          },
          {
            name: "Agent.AddExternalResource",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/FormView.vue"
              ),
          },
          {
            name: "Agent.EditExternalResource",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Resource" */ "@/components/View/Agent/Resource/FormView.vue"
              ),
          },
        ],
      },
      // Customers
      {
        path: "customers",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Customer" */ "@/components/View/Agent/Customer/View.vue"
          ),
        children: [
          {
            name: "Agent.Customers",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Customer" */ "@/components/View/Agent/Customer/TableView.vue"
              ),
          },
          {
            name: "Agent.AddCustomer",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Customer" */ "@/components/View/Agent/Customer/FormView.vue"
              ),
          },
          {
            name: "Agent.EditCustomer",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Customer" */ "@/components/View/Agent/Customer/FormView.vue"
              ),
          },
        ],
      },
      // Contracts
      {
        path: "contracts",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/View.vue"
          ),
        children: [
          {
            name: "Agent.Contracts",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/ListView.vue"
              ),
          },
          {
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/View.vue"
              ),
            children: [
              {
                name: "Agent.AddContract",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/FormView.vue"
                  ),
              },
            ],
          },
          {
            name: "Agent.EditContract",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/FormView.vue"
              ),
          },
          {
            path: `:id(${uuid})/periods`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/View.vue"
              ),
            children: [
              {
                path: "",
                redirect: { name: "Agent.AddPeriod" },
              },
              {
                name: "Agent.AddPeriod",
                path: "add",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/FormView.vue"
                  ),
              },
              {
                name: "Agent.EditPeriod",
                path: `:periodId(${uuid})`,
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/FormView.vue"
                  ),
              },
            ],
          },
          {
            path: "fallback",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/View.vue"
              ),
            children: [
              {
                name: "Agent.EditFallback",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Period/FormView.vue"
                  ),
              },
            ],
          },
          {
            name: "Agent.InconveniencePayLevels",
            path: "inconvenience-pay-levels",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/InconveniencePayLevels/FormView.vue"
              ),
          },
          {
            name: "Agent.Holidays",
            path: "holidays",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Contract" */ "@/components/View/Agent/Contract/Holidays/FormView.vue"
              ),
          },
        ],
      },
      // Languages
      {
        path: "languages",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Language" */ "@/components/View/Agent/Language/View.vue"
          ),
        children: [
          {
            name: "Agent.Languages",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Language" */ "@/components/View/Agent/Language/TableView.vue"
              ),
          },
          {
            name: "Agent.AddLanguage",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Language" */ "@/components/View/Agent/Language/FormView.vue"
              ),
          },
          {
            name: "Agent.EditLanguage",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Language" */ "@/components/View/Agent/Language/FormView.vue"
              ),
          },
        ],
      },
      // Billing
      {
        path: "billing",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/View.vue"
          ),
        children: [
          {
            name: "Agent.Transactions",
            path: "transactions",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/TableView.vue"
              ),
          },
          {
            path: "batches",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/Batch/View.vue"
              ),
            children: [
              {
                name: "Agent.Batches",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/Batch/TableView.vue"
                  ),
              },
              {
                name: "Agent.EditBatch",
                path: `:id(${uuid})`,
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/Batch/FormView.vue"
                  ),
              },
            ],
          },
          {
            path: "export-groups",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/ExportGroup/View.vue"
              ),
            children: [
              {
                name: "Agent.ExportGroups",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/ExportGroup/TableView.vue"
                  ),
              },
              {
                name: "Agent.EditExportGroup",
                path: `:id(${uuid})`,
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/ExportGroup/FormView.vue"
                  ),
              },
            ],
          },
          {
            name: "Agent.BilledTransactions",
            path: "billed-transactions",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/TableView.vue"
              ),
          },
          {
            path: `invoices`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/Invoices/View.vue"
              ),
            children: [
              {
                name: "Agent.Invoices",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/Invoices/TableView.vue"
                  ),
              },
              {
                name: "Agent.UpdateInvoices",
                path: `:exportGroupId(${uuid})`,
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Billing" */ "@/components/View/Agent/Billing/Invoices/FormView.vue"
                  ),
              },
            ],
          },
        ],
      },
      // Statistics **OLD**
      {
        path: "statistics-old",
        component: () =>
          import(
            /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/View.vue"
          ),
        children: [
          {
            name: "Agent.StatisticsOld",
            path: "",
            redirect: "Agent.StatisticsOldGeneral",
          },
          {
            path: "general",
            component: () =>
              import(
                /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/General/View.vue"
              ),
            children: [
              {
                name: "Agent.StatisticsOldGeneral",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/General/FormView.vue"
                  ),
              },
            ],
          },
          {
            path: "transaction-rows",
            component: () =>
              import(
                /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/TransactionRows/View.vue"
              ),
            children: [
              {
                name: "Agent.StatisticsOldTransactionRows",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/TransactionRows/FormView.vue"
                  ),
              },
            ],
          },
          {
            path: "feedbacks",
            component: () =>
              import(
                /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/Feedbacks/View.vue"
              ),
            children: [
              {
                name: "Agent.StatisticsOldFeedbacks",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/Feedbacks/FormView.vue"
                  ),
              },
            ],
          },
          {
            path: "assignments",
            component: () =>
              import(
                /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/Assignments/View.vue"
              ),
            children: [
              {
                name: "Agent.StatisticsOldAssignments",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.StatisticsOld" */ "@/components/View/Agent/StatisticsOld/Assignments/FormView.vue"
                  ),
              },
            ],
          },
        ],
      },
      // Statistics
      {
        path: "statistics",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Statistics" */ "@/components/View/Agent/Statistics/View.vue"
          ),
        children: [
          {
            name: "Agent.Statistics",
            path: "",
            redirect: "Agent.StatisticsCount",
          },
          {
            path: "count",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Statistics" */ "@/components/View/Agent/Statistics/Count/View.vue"
              ),
            children: [
              {
                name: "Agent.StatisticsCount",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Statistics" */ "@/components/View/Agent/Statistics/Count/FormView.vue"
                  ),
              },
            ],
          },
          {
            path: "other",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Statistics" */ "@/components/View/Agent/Statistics/Other/View.vue"
              ),
            children: [
              {
                name: "Agent.StatisticsOther",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.Statistics" */ "@/components/View/Agent/Statistics/Other/FormView.vue"
                  ),
              },
            ],
          },
        ],
      },
      // Users
      {
        path: "users",
        component: () =>
          import(
            /* webpackChunkName: "Agent.User" */ "@/components/View/Agent/User/View.vue"
          ),
        children: [
          {
            name: "Agent.Users",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.User" */ "@/components/View/Agent/User/TableView.vue"
              ),
          },
          {
            name: "Agent.AddUser",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.User" */ "@/components/View/Agent/User/FormView.vue"
              ),
          },
          {
            name: "Agent.EditUser",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.User" */ "@/components/View/Agent/User/FormView.vue"
              ),
          },
        ],
      },
      // News Articles
      {
        path: "news",
        component: () =>
          import(
            /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/View.vue"
          ),
        children: [
          {
            name: "Agent.NewsArticles",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/TableView.vue"
              ),
          },
          {
            name: "Agent.AddNewsArticle",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/FormView.vue"
              ),
          },
          {
            name: "Agent.EditNewsArticle",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/FormView.vue"
              ),
          },
          {
            path: "groups",
            component: () =>
              import(
                /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/Groups/View.vue"
              ),
            children: [
              {
                name: "Agent.NewsGroups",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/Groups/TableView.vue"
                  ),
              },
              {
                name: "Agent.AddNewsGroup",
                path: "add",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/Groups/FormView.vue"
                  ),
              },
              {
                name: "Agent.EditNewsGroup",
                path: `:id(${uuid})`,
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.News" */ "@/components/View/Agent/News/Groups/FormView.vue"
                  ),
              },
            ],
          },
        ],
      },
      // Settings
      {
        path: "settings",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Settings" */ "@/components/View/Agent/Settings/View.vue"
          ),
        children: [
          {
            name: "Agent.Settings",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Settings" */ "@/components/View/Agent/Settings/FormView.vue"
              ),
          },
        ],
      },
      // Business Rules
      {
        path: "business-rules",
        component: () =>
          import(
            /* webpackChunkName: "Agent.BusinessRules" */ "@/components/View/Agent/BusinessRules/View.vue"
          ),
        children: [
          {
            name: "Agent.BusinessRules",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.BusinessRules" */ "@/components/View/Agent/BusinessRules/FormView.vue"
              ),
          },
        ],
      },
      // Feedback
      {
        path: `assignments/:assignmentId(${uuid})/feedback`,
        component: () =>
          import(
            /* webpackChunkName: "Agent.Feedback" */ "@/components/View/Agent/Feedback/View.vue"
          ),
        children: [
          {
            name: "Agent.Feedback",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Feedback" */ "@/components/View/Agent/Feedback/FormView.vue"
              ),
          },
        ],
      },
      // Feedback Types
      {
        path: "feedback-types",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Feedback" */ "@/components/View/Agent/Feedback/Type/View.vue"
          ),
        children: [
          {
            name: "Agent.FeedbackTypes",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Feedback" */ "@/components/View/Agent/Feedback/Type/FormView.vue"
              ),
          },
        ],
      },
      // Agency
      {
        path: "agency",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Agency" */ "@/components/View/Agent/Agency/View.vue"
          ),
        children: [
          {
            name: "Agent.Agency",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Agency" */ "@/components/View/Agent/Agency/FormView.vue"
              ),
          },
        ],
      },
      // Fortnox
      {
        path: "fortnox",
        component: () =>
          import(
            /* webpackChunkName: "Agent.Fortnox" */ "@/components/View/Agent/Fortnox/View.vue"
          ),
        children: [
          {
            name: "Agent.FortnoxConnect",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Fortnox" */ "@/components/View/Agent/Fortnox/ConnectView.vue"
              ),
          },
          {
            name: "Agent.FortnoxComplete",
            path: "complete",
            component: () =>
              import(
                /* webpackChunkName: "Agent.Fortnox" */ "@/components/View/Agent/Fortnox/CompleteView.vue"
              ),
          },
        ],
      },
      // Assignment Requests
      {
        path: "assignment-requests",
        component: () =>
          import(
            /* webpackChunkName: "Agent.AssignmentRequest" */ "@/components/View/Agent/AssignmentRequest/View.vue"
          ),
        children: [
          {
            path: "kammarkollegiet",
            component: () =>
              import(
                /* webpackChunkName: "Agent.AssignmentRequest" */ "@/components/View/Agent/AssignmentRequest/Kammarkollegiet/View.vue"
              ),
            children: [
              {
                name: "Agent.Kammarkollegiet.AssignmentRequests",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.AssignmentRequest" */ "@/components/View/Agent/AssignmentRequest/Kammarkollegiet/TableView.vue"
                  ),
              },
              {
                name: "Agent.Kammarkollegiet.ProcessAssignmentRequest",
                path: ":orderNumber",
                component: () =>
                  import(
                    /* webpackChunkName: "Agent.AssignmentRequest" */ "@/components/View/Agent/AssignmentRequest/Kammarkollegiet/FormView.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
