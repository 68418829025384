






















import { UiConflict } from "./component";

import AsyncButton from "@/components/AsyncButton.vue";

export default UiConflict.extend({
  components: {
    AsyncButton,
  },
});
