




import { Component, Mixins } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { UserContext } from "@/plugins/Auth/state";
import { UserType } from "@/plugins/Auth/types";
import { ErrorMixin } from "@/mixins/ErrorMixin";
import { ExpiredUserError } from "@/plugins/Auth/errors";

@Component({ components: { Loader } })
export default class App extends Mixins(ErrorMixin) {
  mounted(): void {
    this.$nextTick(() => {
      this.$auth
        .getUser()
        .then(() => {
          if (UserContext.getters.type !== UserType.Admin) {
            // Connect to SignalR.
            this.$signalR
              .connect(
                UserContext.getters.type,
                () => UserContext.getters.accessToken
              )
              .then(() => {
                // Start listening to SettingsUpdated.
                this.$signalR.on("SettingsUpdated", () => {
                  UserContext.actions.getAndSetSettings();
                });
              })
              .catch((error: unknown) => {
                this.displayError(error);
              });
          }

          // Get Return URL.
          const returnUrl = sessionStorage.getItem("return-url");

          // Attempt to go to return url if it's set.
          if (returnUrl) {
            sessionStorage.removeItem("return-url");
            sessionStorage.removeItem("silent-redirect");
            this.$router.push(returnUrl).catch(() => {
              this.$router.push({ name: "App" }).catch(() => {
                if (location.pathname !== "/error/something-went-wrong") {
                  location.pathname = "/error/something-went-wrong";
                }
              });
            });
          } else {
            this.$router.push({ name: "Home" }).catch(() => {
              this.$router.push({ name: "SignOut" }).catch(() => {
                this.$router.push({ name: "App" }).catch(() => {
                  if (location.pathname !== "/error/something-went-wrong") {
                    location.pathname = "/error/something-went-wrong";
                  }
                });
              });
            });
          }
        })
        .catch((error: unknown) => {
          if (!(error instanceof ExpiredUserError)) {
            this.$auth.signIn();
          }
        });
    });
  }
}
