




















import Loader from "@/components/Loader.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: { Loader } })
export default class ErrorServerIsDown extends Vue {}
