import { DomainError } from "../DomainError";

/**
 * An error with a user-friendly error message that is displayed as a
 * snackbar message.
 */
export class GenericUserFriendlyError extends DomainError {
  /**
   * @param message Error message.
   * @param userMessage User-friendly error message.
   */
  constructor(message: string, userMessage: string) {
    // Set the name of the error.
    super("GenericUserFriendlyError");

    // Set the error message.
    this.setMessage(message);

    // Set the user-friendly error message.
    this.setUserMessage(userMessage);
  }
}
