import { Assertion } from "../types/Assertion";
import { AssertionError } from "../errors/AssertionError";

/**
 * Asserts that the value is a `Record<string, unknown>`.
 *
 * @param value Value to assert.
 *
 * @throws {@link AssertionError}
 * This error is thrown if the assertion fails.
 */
export const assertIsRecord: Assertion<Record<string, unknown>> = (value) => {
  if (typeof value !== "object" || value === null) {
    throw new AssertionError("*value* is not a `Record<string, unknown>`.");
  }
};
