import { Address, formatAddress } from "@/types/Address";
import { formatPhoneNumber } from "@/types/Brands/PhoneNumber";
import { formatTimestamp, relativeTimestamp } from "@/types/Brands/Timestamp";
import Vue from "vue";
import { Locale } from "./Locale";

Vue.filter("address", (value?: Address) => formatAddress(value) || "");
Vue.filter("currency", (value?: string) =>
  Intl.NumberFormat(Locale, { currency: "SEK", style: "currency" }).format(
    Number.parseFloat(value || "0")
  )
);
Vue.filter("date", (value?: string) => formatTimestamp(value, "Y-MM-DD"));
Vue.filter("datetime", (value?: string) =>
  formatTimestamp(value, "Y-MM-DD HH:mm")
);
Vue.filter("relative", (value?: string) => relativeTimestamp(value));
Vue.filter("decimal", (value?: string) =>
  Intl.NumberFormat(Locale).format(Number.parseFloat(value || "0"))
);
Vue.filter("lower", (value?: string) =>
  (value || "").toLocaleLowerCase(Locale)
);
Vue.filter("phone", formatPhoneNumber);
Vue.filter("pre", (value?: string) => (value || "").replace(/\n/g, "<br>"));
Vue.filter("time", (value?: string) => formatTimestamp(value, "HH:mm"));
Vue.filter("upper", (value?: string) =>
  (value || "").toLocaleUpperCase(Locale)
);
