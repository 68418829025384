import { isArray } from "@/types/TypeGuard";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

@Component({ inheritAttrs: false })
export class TextField extends Vue {
  /** Textarea flag. */
  @Prop({ type: Boolean, default: false })
  readonly area!: boolean;

  /** Input */
  @Ref("input")
  readonly input!: Vue;

  /** Default autocomplete to off. */
  get autocomplete(): string {
    return this.$attrs.autocomplete || "off";
  }

  /** Clearable flag. */
  get clearable(): boolean {
    return this.$attrs.clearable === "";
  }

  /** Error messages. */
  get errorMessages(): string[] {
    const errorMessages: unknown = this.$attrs["error-messages"] || [];

    if (isArray(errorMessages, "string")) {
      return errorMessages;
    }

    return [];
  }

  /** Vue mounted life-cycle hook. */
  mounted(): void {
    // Trigger watchers after mounting to ensure the input ref is set.
    this.$nextTick(() => {
      if (this.input && this.input.$el instanceof HTMLDivElement) {
        this.onClearableChange(this.clearable);
        this.onErrorMessagesChange(this.errorMessages);

        /* Fix for counter being visible for screen readers when the field is
           disabled. */
        const counter = this.input.$el.querySelector(".v-counter");

        if (counter instanceof HTMLDivElement) {
          if (this.$attrs.disabled) {
            counter.setAttribute("aria-hidden", "true");
          } else {
            counter.removeAttribute("aria-hidden");
          }
        }
      }
    });
  }

  /**
   * Translates the aria-label of the clear icon to Swedish.
   *
   * @remarks
   * Solves WCAG 1.1.1 for text-field clear buttons with ARIA6.
   *
   * @see {@link https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html | WCAG 1.1.1}
   * @see {@link https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA6.html | ARIA6}
   */
  @Watch("clearable")
  onClearableChange(clearable: boolean): void {
    if (clearable) {
      const iconElement = this.input.$el.querySelector<HTMLButtonElement>(
        '[aria-label="clear icon"]'
      );
      if (iconElement) {
        iconElement.setAttribute("aria-label", "Rensa ikon");
      }
    }
  }

  /**
   * Properly handle aria-invalid and aria-describedby.
   *
   * @remarks
   * Solves WCAG 3.3.1 for text-field errors with ARIA21.
   *
   * @see {@link https://www.w3.org/WAI/WCAG21/Understanding/error-identification.html | WCAG 3.3.1}
   * @see {@link https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA21.html | ARIA21}
   *
   * @param errorMessages Array of error messages.
   */
  @Watch("errorMessages", { deep: true })
  onErrorMessagesChange(errorMessages: string[]): void {
    const invalid = errorMessages.length > 0;

    const inputElement = this.input.$el.querySelector<
      HTMLInputElement | HTMLTextAreaElement
    >("input, textarea");
    const messagesElement =
      this.input.$el.querySelector<HTMLDivElement>(".v-messages");

    if (inputElement && messagesElement) {
      messagesElement.setAttribute("id", inputElement.id + "-messages");
      inputElement.setAttribute("aria-invalid", invalid.toString());
      inputElement.setAttribute(
        "aria-describedby",
        inputElement.id + "-messages"
      );
    }
  }
}
