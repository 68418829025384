





















import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";

@Component({ components: { Loader } })
export default class ErrorNotFound extends Vue {
  loading = true;

  mounted(): void {
    this.$nextTick(() => {
      if (this.$route.name === "ErrorNotFound") {
        this.loading = false;
      } else {
        this.$auth
          .getUser()
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            sessionStorage.setItem("return-url", this.$route.fullPath);
            this.$router.push({ name: "App" });
          });
      }
    });
  }
}
