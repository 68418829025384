import { registerModule } from "@/plugins/Store";
import { HttpStatusCode } from "@/types/Enums/HttpStatusCode";
import { inObject } from "@/types/TypeGuard";
import { AxiosResponse } from "axios";
import { escape, isObject } from "lodash";
import {
  Actions,
  Getters,
  Module as VuexModule,
  Mutations,
} from "vuex-smart-module";
import "../state";

class ModuleState {
  forceable = false;
  message = "";
  reject: (value: void | PromiseLike<void>) => void = () => {
    return;
  };
  resolve: (value: void | PromiseLike<void>) => void = () => {
    return;
  };
  show = false;
}

class ModuleGetters extends Getters<ModuleState> {}

class ModuleMutations extends Mutations<ModuleState> {
  resetState() {
    Object.assign(this.state, new ModuleState());
  }
  setForceable(forceable: boolean) {
    this.state.forceable = forceable;
  }
  setMessage(message: string) {
    this.state.message = message;
  }
  setReject(reject: (value: void | PromiseLike<void>) => void) {
    this.state.reject = reject;
  }
  setResolve(resolve: (value: void | PromiseLike<void>) => void) {
    this.state.resolve = resolve;
  }
  setShow(show: boolean) {
    this.state.show = show;
  }
}

class ModuleActions extends Actions<
  ModuleState,
  ModuleGetters,
  ModuleMutations,
  ModuleActions
> {
  async showConflict(response: AxiosResponse<unknown>) {
    if (
      response.status === HttpStatusCode.CONFLICT &&
      isObject(response.data) &&
      inObject("errors", response.data) &&
      Array.isArray(response.data.errors) &&
      response.data.errors.length > 0
    ) {
      const errors: { forceable: boolean; message: string }[] =
        response.data.errors;
      const forceable = errors.every((error) => error.forceable);
      const messages = [];

      for (const error of errors) {
        messages.push(escape(error.message));
      }

      let message = "<b>●</b> " + messages.join("<br><b>●</b> ");
      message = message.trim();

      if (messages.length > 1) {
        message = message.slice(0, -12);
      }

      try {
        await new Promise<void>((resolve, reject) => {
          this.mutations.setForceable(forceable);
          this.mutations.setMessage(message);
          this.mutations.setReject(reject);
          this.mutations.setResolve(resolve);
          this.mutations.setShow(true);
        });
      } catch (error: unknown) {
        this.mutations.resetState();
        return false;
      }

      this.mutations.resetState();
      return true;
    }

    this.mutations.resetState();
    return false;
  }
}

const Module = new VuexModule({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const UiConflictContext = registerModule("Ui/Conflict", Module);
