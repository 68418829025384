class ApiError extends Error {
  constructor(message: string) {
    super(`Api Error: ${message}`);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class ConflictRejectedError extends ApiError {
  constructor() {
    super("Conflict rejected.");
  }
}

export class RejectedCounterError extends ApiError {
  constructor(responseCounter = 0, currentCounter = 0) {
    super(
      "Response rejected since it's counter didn't match the current counter." +
        `Expected ${currentCounter} but response had ${responseCounter}.`
    );
  }
}

export class RejectedDuplicateError extends ApiError {
  constructor() {
    super("Rejected duplicate request.");
  }
}
