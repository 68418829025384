class AuthError extends Error {
  constructor(message: string) {
    super(`Auth Error: ${message}`);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NoUserFoundError extends AuthError {
  constructor() {
    super("No user found.");
  }
}

export class ExpiredUserError extends AuthError {
  constructor() {
    super("OIDC User is expired.");
  }
}

export class InvalidUserError extends AuthError {
  constructor() {
    super("OIDC User is invalid.");
  }
}

export class MissingUrlsError extends AuthError {
  constructor() {
    super("The required URI's must be supplied.");
  }
}
