import { ImmutableArray } from "@/old-domain/types/Immutable";

/**
 * Builds an API URL.
 *
 * @param path Simplified path string without IDs.
 * @param ids IDs to insert into the path to make a URL.
 */
export const getUrl = (
  path: string,
  ids: ImmutableArray<
    string | Record<"toString", (...args: unknown[]) => string>
  > = []
): string => {
  let url = "";

  if (ids.length > 0) {
    const pathParts = path.split("/");

    if (ids.length <= pathParts.length) {
      for (const id of ids) {
        if (id.toString().length > 0) {
          url += pathParts.shift() + "/" + id + "/";
        } else {
          url += pathParts.shift() + "/";
        }
      }

      url += pathParts.join("/");

      if (/\/$/.test(url)) {
        url = url.substr(0, url.length - 1);
      }
    } else {
      throw new Error("To many IDs supplied.");
    }
  } else {
    url = path;
  }

  return url;
};
