import { isTimestamp, Timestamp } from "./Brands/Timestamp";
import { ifInObject, inObject, isObject } from "./TypeGuard";

/** Meta data properties. */
export interface MetaData {
  createdBy: string;
  createdOn: Timestamp;
  isDeleted: boolean;
  modifiedBy?: string;
  modifiedOn?: Timestamp;
}

/**
 * Type Guard for Meta data properties.
 *
 * @param value Value to check.
 */
export const isMetaData = (value: unknown): value is MetaData => {
  return (
    isObject(value) &&
    inObject("createdBy", value, "string") &&
    inObject("createdOn", value, isTimestamp) &&
    inObject("isDeleted", value, "boolean") &&
    ifInObject("modifiedBy", value, "string") &&
    ifInObject("modifiedOn", value, isTimestamp)
  );
};

/** Abstract of Meta data properties. */
export abstract class MetaData {
  /**
   * Validate and set data.
   *
   * @param data API or State data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: Record<string, any>) {
    if (isMetaData(data)) {
      this.createdBy = data.createdBy;
      this.createdOn = data.createdOn;
      this.isDeleted = data.isDeleted;
      this.modifiedBy = data.modifiedBy;
      this.modifiedOn = data.modifiedOn;
    } else {
      throw new Error("Invalid data.");
    }
  }
}
