import { uniq } from "lodash";
import { assertIsRecord } from "../assertions/assertIsRecord";
import { DomainError } from "../DomainError";

/** An assertion has failed. */
export class AssertionError extends DomainError {
  /**
   * @param message Error message.
   * @param userMessage User-friendly error message.
   * @param appendError Whether to append the current error message.
   * @param value The value to get variable values from.
   */
  constructor(
    message: string,
    userMessage?: string,
    appendError = false,
    value?: unknown
  ) {
    // Set the name of the error.
    super("AssertionError");

    // Set the error message.
    this.setMessage(message, appendError, value);

    // Set the user-friendly error message.
    this.setUserMessage(userMessage, value);
  }

  /**
   * Set the error message.
   *
   * @param message Error message.
   * @param appendError Whether to append the current error message.
   * @param value Value to get variable values from.
   */
  setMessage(message: string, appendError = false, value?: unknown): void {
    let newMessage = message;

    if (value != null) {
      newMessage = this.replaceVariables(newMessage, value);
    }

    if (appendError) {
      newMessage += "\n" + this.toString();
    }

    super.setMessage(newMessage);
  }

  /**
   * Shorthand to set both the error message and user-friendly error message.
   *
   * @param message Error message.
   * @param userMessage User-friendly error message.
   */
  setMessages(message: string, userMessage: string): void {
    this.setMessage(message);
    this.setUserMessage(userMessage);
  }

  /**
   * Set the user-friendly error message.
   *
   * @param message Error message.
   * @param value Value to get variable values from.
   */
  setUserMessage(message?: string, value?: unknown): void {
    let newMessage = message;

    if (newMessage) {
      if (value != null) {
        newMessage = this.replaceVariables(newMessage, value, true);
      }

      const userMessage = this.toString(true);
      if (userMessage) {
        newMessage += "<br>" + userMessage;
      }

      super.setUserMessage(newMessage);
    }
  }

  /**
   * Replaces `%someName%` with the value of `value.someName` cast to a string.
   *
   * @param message Message to replace variables in.
   * @param value Value to get variable values from.
   *
   * @returns New message with the variables replaced.
   */
  private replaceVariables(message: string, value: unknown, forUser = false) {
    const newMessage = message;

    try {
      assertIsRecord(value);

      const variables = uniq(newMessage.match(/%[a-zA-Z]+%/g) || []);

      for (const variable of variables) {
        const property = variable.replace(/^%+|%+$/g, "");

        if (property in value) {
          const propertyValue = value[property];
          let replacement = "";

          if (typeof propertyValue === "string") {
            replacement = propertyValue;
          } else if (typeof propertyValue === "number") {
            replacement = propertyValue.toString(10);
          } else if (typeof propertyValue === "boolean") {
            replacement = propertyValue.toString();
          } else if (
            typeof propertyValue === "object" &&
            propertyValue !== null &&
            "toString" in propertyValue &&
            typeof propertyValue.toString === "function"
          ) {
            replacement = propertyValue.toString();
          }

          if (!replacement && forUser) {
            replacement = "(tomt)";
          }

          if (replacement) {
            return newMessage
              .replace(new RegExp(`\\*${variable}\\*`, "g"), `*${replacement}*`)
              .replace(
                new RegExp(variable, "g"),
                forUser
                  ? `<em>${replacement}</em>`
                  : `“${replacement}” (${typeof propertyValue})`
              );
          }
        }
      }
    } catch {
      /* Ignore */
    }

    return newMessage;
  }
}
