import Vue from "vue";
import { UserContext } from "./Auth/state";

Vue.component("Req", {
  props: {
    title: {
      type: String,
      default: "(krävs)",
    },
  },
  computed: {
    isCustomer() {
      return UserContext.getters.isCustomer;
    },
  },
  render(h) {
    if ((this as Vue & Record<"isCustomer", boolean>).isCustomer) {
      return h(
        "abbr",
        {
          class: ["red--text", "text-decoration-none"],
          attrs: {
            title: this.title,
          },
        },
        "*"
      );
    }

    return h();
  },
});
