import { UiConflictContext } from "@/components/Ui/Conflict/state";
import { Immutable } from "@/old-domain/types/Immutable";
import { JsonObject } from "@/old-domain/types/Json";
import { UserContext } from "@/plugins/Auth/state";
import { Router } from "@/plugins/Router";
import { HttpMethod } from "@/types/Enums/HttpMethod";
import { HttpStatusCode } from "@/types/Enums/HttpStatusCode";
import { AxiosError } from "axios";
import { Api } from ".";
import { deepClone } from "../Helpers";
import { ConflictRejectedError } from "./errors";
import { ApiRequest, ApiResponse } from "./types";

export const errorHandler = async <T = JsonObject>(
  error: AxiosError<unknown>,
  path: string,
  method: Exclude<HttpMethod, HttpMethod.CONNECT | HttpMethod.TRACE>,
  request: ApiRequest | Immutable<ApiRequest> = {},
  blob = false
): Promise<ApiResponse<T> | undefined> => {
  if (typeof error.response === "undefined") {
    // Server is down.
    await Router.push({ name: "ErrorServerIsDown" });
    return;
  }

  switch (error.response.status) {
    // Authentication errors.
    case HttpStatusCode.NETWORK_AUTHENTICATION_REQUIRED:
    case HttpStatusCode.PROXY_AUTHENTICATION_REQUIRED:
    case HttpStatusCode.UNAUTHORIZED:
      // Set return url and redirect the browser to the domain root.
      if (!sessionStorage.getItem("return-url")) {
        sessionStorage.setItem(
          "return-url",
          location.pathname + location.search + location.hash
        );
      }

      // Unset user.
      await UserContext.actions.unsetUser();

      // Restart app.
      await Router.push({ name: "App" });
      break;

    // Conflict.
    case HttpStatusCode.CONFLICT:
      if (!request.headers || !("Force" in request.headers)) {
        const force = await UiConflictContext.actions.showConflict(
          error.response
        );

        if (force) {
          const newRequest = deepClone(request) as ApiRequest;
          if (newRequest.headers) {
            newRequest.headers.Force = "true";
          } else {
            newRequest.headers = { Force: "true" };
          }

          // Redo the request with the Force header.
          return Api(path, method, newRequest, blob);
        }

        // Throw the silent conflict rejected error.
        throw new ConflictRejectedError();
      }
      break;

    // Error responses.
    case HttpStatusCode.BAD_REQUEST:
    case HttpStatusCode.UNPROCESSABLE_ENTITY:
      throw error.response.data;

    // Azure gateway errors or server is down:
    case HttpStatusCode.BAD_GATEWAY:
    case HttpStatusCode.GATEWAY_TIMEOUT:
    case HttpStatusCode.SERVICE_UNAVAILABLE:
      if (!sessionStorage.getItem("return-url")) {
        sessionStorage.setItem(
          "return-url",
          location.pathname + location.search + location.hash
        );
      }
      Router.push({ name: "ErrorServerIsDown" });
      break;

    // Something went wrong.
    default:
      if (!sessionStorage.getItem("return-url")) {
        sessionStorage.setItem(
          "return-url",
          location.pathname + location.search + location.hash
        );
      }
      Router.push({ name: "ErrorSomethingWentWrong" });
      break;
  }
};
