import { AssertionError } from "./errors/AssertionError";
import { Assertion } from "./types/Assertion";
import { Constructor } from "./types/Constructor";

/** Constructor type. */
type ValueTypeCtor = Constructor<ValueType<string | number | bigint>>;

/** For for implementing static members in child classes. */
export type ValueTypeStatic<
  T extends ValueType<string | number | bigint>,
  V extends Array<unknown> = []
> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: Array<any>): T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assert: Assertion<T, V>;
};

/** Type objects define new value types. */
export abstract class ValueType<P extends string | number | bigint = string> {
  /**
   * Asserts that the value is an instance of `target`.
   *
   * @param value The value to assert.
   *
   * @throws {@link AssertionError}
   * Thrown if the value is not an instance of `target`.
   */
  protected static assertInternal<T extends ValueTypeCtor>(
    value: unknown,
    target: T
  ): asserts value is InstanceType<T> {
    if (value instanceof target) {
      return;
    }
    throw new AssertionError(
      `*value* is not an instance of \`${target.name}\`.`
    );
  }

  /** The value as a primitive. */
  protected abstract readonly primitiveValue: P;

  /** The value as a string. */
  protected abstract readonly stringValue: string;

  /** @returns The value as a string. */
  toString(): string {
    return this.stringValue;
  }

  /** @returns The value as a primitive. */
  valueOf(): P {
    return this.primitiveValue;
  }
}
