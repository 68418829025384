import { LocaleInput } from "@fullcalendar/common";

// Adds the missing week property to locale input interface.
interface LocaleInputFixed extends LocaleInput {
  week: {
    dow: number;
    doy: number;
  };
}

export const FullCalendar: LocaleInputFixed = {
  code: "sv",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: "Förra",
    next: "Nästa",
    today: "Idag",
    month: "Månad",
    week: "Vecka",
    day: "Dag",
    list: "Lista",
  },
  weekText: "",
  allDayText: "Heldag",
  moreLinkText: "till",
  noEventsText: "Inga händelser att visa.",
};
