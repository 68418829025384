import { AxiosResponse } from "axios";
import { ApiResponseHeaders } from "./types";

export const getCamelCaseHeaders = (
  headers: AxiosResponse["headers"]
): ApiResponseHeaders => {
  const camelCaseHeaders: ApiResponseHeaders = {};

  if (
    "content-disposition" in headers &&
    typeof headers["content-disposition"] === "string" &&
    headers["content-disposition"].length > 0
  ) {
    camelCaseHeaders.ContentDisposition = headers["content-disposition"];
  }

  if (
    "location" in headers &&
    typeof headers["location"] === "string" &&
    headers["location"].length > 0
  ) {
    camelCaseHeaders.Location = headers["location"];
  }

  if (
    "total-count" in headers &&
    typeof headers["total-count"] === "string" &&
    headers["total-count"].length > 0
  ) {
    camelCaseHeaders.TotalCount = parseInt(headers["total-count"], 10);
  }

  return camelCaseHeaders;
};
