export enum Color {
  // Vuetify Theme
  Primary = "primary",
  Secondary = "secondary",
  Accent = "accent",
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
  // Material - Red
  Red = "red",
  RedAccent1 = "red accent-1",
  RedAccent2 = "red accent-2",
  RedAccent3 = "red accent-3",
  RedAccent4 = "red accent-4",
  RedDarken1 = "red darken-1",
  RedDarken2 = "red darken-2",
  RedDarken3 = "red darken-3",
  RedDarken4 = "red darken-4",
  RedLighten1 = "red lighten-1",
  RedLighten2 = "red lighten-2",
  RedLighten3 = "red lighten-3",
  RedLighten4 = "red lighten-4",
  RedLighten5 = "red lighten-5",
  // Material - Pink
  Pink = "pink",
  PinkAccent1 = "pink accent-1",
  PinkAccent2 = "pink accent-2",
  PinkAccent3 = "pink accent-3",
  PinkAccent4 = "pink accent-4",
  PinkDarken1 = "pink darken-1",
  PinkDarken2 = "pink darken-2",
  PinkDarken3 = "pink darken-3",
  PinkDarken4 = "pink darken-4",
  PinkLighten1 = "pink lighten-1",
  PinkLighten2 = "pink lighten-2",
  PinkLighten3 = "pink lighten-3",
  PinkLighten4 = "pink lighten-4",
  PinkLighten5 = "pink lighten-5",
  // Material - Purple
  Purple = "purple",
  PurpleAccent1 = "purple accent-1",
  PurpleAccent2 = "purple accent-2",
  PurpleAccent3 = "purple accent-3",
  PurpleAccent4 = "purple accent-4",
  PurpleDarken1 = "purple darken-1",
  PurpleDarken2 = "purple darken-2",
  PurpleDarken3 = "purple darken-3",
  PurpleDarken4 = "purple darken-4",
  PurpleLighten1 = "purple lighten-1",
  PurpleLighten2 = "purple lighten-2",
  PurpleLighten3 = "purple lighten-3",
  PurpleLighten4 = "purple lighten-4",
  PurpleLighten5 = "purple lighten-5",
  // Material - Deep Purple
  DeepPurple = "deep-purple",
  DeepPurpleAccent1 = "deep-purple accent-1",
  DeepPurpleAccent2 = "deep-purple accent-2",
  DeepPurpleAccent3 = "deep-purple accent-3",
  DeepPurpleAccent4 = "deep-purple accent-4",
  DeepPurpleDarken1 = "deep-purple darken-1",
  DeepPurpleDarken2 = "deep-purple darken-2",
  DeepPurpleDarken3 = "deep-purple darken-3",
  DeepPurpleDarken4 = "deep-purple darken-4",
  DeepPurpleLighten1 = "deep-purple lighten-1",
  DeepPurpleLighten2 = "deep-purple lighten-2",
  DeepPurpleLighten3 = "deep-purple lighten-3",
  DeepPurpleLighten4 = "deep-purple lighten-4",
  DeepPurpleLighten5 = "deep-purple lighten-5",
  // Material - Indigo
  Indigo = "indigo",
  IndigoAccent1 = "indigo accent-1",
  IndigoAccent2 = "indigo accent-2",
  IndigoAccent3 = "indigo accent-3",
  IndigoAccent4 = "indigo accent-4",
  IndigoDarken1 = "indigo darken-1",
  IndigoDarken2 = "indigo darken-2",
  IndigoDarken3 = "indigo darken-3",
  IndigoDarken4 = "indigo darken-4",
  IndigoLighten1 = "indigo lighten-1",
  IndigoLighten2 = "indigo lighten-2",
  IndigoLighten3 = "indigo lighten-3",
  IndigoLighten4 = "indigo lighten-4",
  IndigoLighten5 = "indigo lighten-5",
  // Material - Blue
  Blue = "blue",
  BlueAccent1 = "blue accent-1",
  BlueAccent2 = "blue accent-2",
  BlueAccent3 = "blue accent-3",
  BlueAccent4 = "blue accent-4",
  BlueDarken1 = "blue darken-1",
  BlueDarken2 = "blue darken-2",
  BlueDarken3 = "blue darken-3",
  BlueDarken4 = "blue lighten-5",
  BlueLighten1 = "blue lighten-1",
  BlueLighten2 = "blue lighten-2",
  BlueLighten3 = "blue lighten-3",
  BlueLighten4 = "blue lighten-4",
  BlueLighten5 = "blue lighten-5",
  // Material - Light Blue
  LightBlue = "light-blue",
  LightBlueAccent1 = "light-blue accent-1",
  LightBlueAccent2 = "light-blue accent-2",
  LightBlueAccent3 = "light-blue accent-3",
  LightBlueAccent4 = "light-blue accent-4",
  LightBlueDarken1 = "light-blue darken-1",
  LightBlueDarken2 = "light-blue darken-2",
  LightBlueDarken3 = "light-blue darken-3",
  LightBlueDarken4 = "light-blue lighten-5",
  LightBlueLighten1 = "light-blue lighten-1",
  LightBlueLighten2 = "light-blue lighten-2",
  LightBlueLighten3 = "light-blue lighten-3",
  LightBlueLighten4 = "light-blue lighten-4",
  LightBlueLighten5 = "light-blue lighten-5",
  // Material - Cyan
  Cyan = "cyan",
  CyanAccent1 = "cyan accent-1",
  CyanAccent2 = "cyan accent-2",
  CyanAccent3 = "cyan accent-3",
  CyanAccent4 = "cyan accent-4",
  CyanDarken1 = "cyan darken-1",
  CyanDarken2 = "cyan darken-2",
  CyanDarken3 = "cyan darken-3",
  CyanDarken4 = "cyan darken-4",
  CyanLighten1 = "cyan lighten-1",
  CyanLighten2 = "cyan lighten-2",
  CyanLighten3 = "cyan lighten-3",
  CyanLighten4 = "cyan lighten-4",
  CyanLighten5 = "cyan lighten-5",
  // Material - Teal
  Teal = "teal",
  TealAccent1 = "teal accent-1",
  TealAccent2 = "teal accent-2",
  TealAccent3 = "teal accent-3",
  TealAccent4 = "teal accent-4",
  TealDarken1 = "teal darken-1",
  TealDarken2 = "teal darken-2",
  TealDarken3 = "teal darken-3",
  TealDarken4 = "teal darken-4",
  TealLighten1 = "teal lighten-1",
  TealLighten2 = "teal lighten-2",
  TealLighten3 = "teal lighten-3",
  TealLighten4 = "teal lighten-4",
  TealLighten5 = "teal lighten-5",
  // Material - Green
  Green = "green",
  GreenAccent1 = "green accent-1",
  GreenAccent2 = "green accent-2",
  GreenAccent3 = "green accent-3",
  GreenAccent4 = "green accent-4",
  GreenDarken1 = "green darken-1",
  GreenDarken2 = "green darken-2",
  GreenDarken3 = "green darken-3",
  GreenDarken4 = "green darken-4",
  GreenLighten1 = "green lighten-1",
  GreenLighten2 = "green lighten-2",
  GreenLighten3 = "green lighten-3",
  GreenLighten4 = "green lighten-4",
  GreenLighten5 = "green lighten-5",
  // Material - Light Green
  LightGreen = "light-green",
  LightGreenAccent1 = "light-green accent-1",
  LightGreenAccent2 = "light-green accent-2",
  LightGreenAccent3 = "light-green accent-3",
  LightGreenAccent4 = "light-green accent-4",
  LightGreenDarken1 = "light-green darken-1",
  LightGreenDarken2 = "light-green darken-2",
  LightGreenDarken3 = "light-green darken-3",
  LightGreenDarken4 = "light-green darken-4",
  LightGreenLighten1 = "light-green lighten-1",
  LightGreenLighten2 = "light-green lighten-2",
  LightGreenLighten3 = "light-green lighten-3",
  LightGreenLighten4 = "light-green lighten-4",
  LightGreenLighten5 = "light-green lighten-5",
  // Material - Lime
  Lime = "lime",
  LimeAccent1 = "lime accent-1",
  LimeAccent2 = "lime accent-2",
  LimeAccent3 = "lime accent-3",
  LimeAccent4 = "lime accent-4",
  LimeDarken1 = "lime darken-1",
  LimeDarken2 = "lime darken-2",
  LimeDarken3 = "lime darken-3",
  LimeDarken4 = "lime darken-4",
  LimeLighten1 = "lime lighten-1",
  LimeLighten2 = "lime lighten-2",
  LimeLighten3 = "lime lighten-3",
  LimeLighten4 = "lime lighten-4",
  LimeLighten5 = "lime lighten-5",
  // Material - Yellow
  Yellow = "yellow",
  YellowAccent1 = "yellow accent-1",
  YellowAccent2 = "yellow accent-2",
  YellowAccent3 = "yellow accent-3",
  YellowAccent4 = "yellow accent-4",
  YellowDarken1 = "yellow darken-1",
  YellowDarken2 = "yellow darken-2",
  YellowDarken3 = "yellow darken-3",
  YellowDarken4 = "yellow darken-4",
  YellowLighten1 = "yellow lighten-1",
  YellowLighten2 = "yellow lighten-2",
  YellowLighten3 = "yellow lighten-3",
  YellowLighten4 = "yellow lighten-4",
  YellowLighten5 = "yellow lighten-5",
  // Material - Amber
  Amber = "amber",
  AmberAccent1 = "amber accent-1",
  AmberAccent2 = "amber accent-2",
  AmberAccent3 = "amber accent-3",
  AmberAccent4 = "amber accent-4",
  AmberDarken1 = "amber darken-1",
  AmberDarken2 = "amber darken-2",
  AmberDarken3 = "amber darken-3",
  AmberDarken4 = "amber darken-4",
  AmberLighten1 = "amber lighten-1",
  AmberLighten2 = "amber lighten-2",
  AmberLighten3 = "amber lighten-3",
  AmberLighten4 = "amber lighten-4",
  AmberLighten5 = "amber lighten-5",
  // Material - Orange
  Orange = "orange",
  OrangeAccent1 = "orange accent-1",
  OrangeAccent2 = "orange accent-2",
  OrangeAccent3 = "orange accent-3",
  OrangeAccent4 = "orange accent-4",
  OrangeDarken1 = "orange darken-1",
  OrangeDarken2 = "orange darken-2",
  OrangeDarken3 = "orange darken-3",
  OrangeDarken4 = "orange darken-4",
  OrangeLighten1 = "orange lighten-1",
  OrangeLighten2 = "orange lighten-2",
  OrangeLighten3 = "orange lighten-3",
  OrangeLighten4 = "orange lighten-4",
  OrangeLighten5 = "orange lighten-5",
  // Material - Deep Orange
  DeepOrange = "deep-orange",
  DeepOrangeAccent1 = "deep-orange accent-1",
  DeepOrangeAccent2 = "deep-orange accent-2",
  DeepOrangeAccent3 = "deep-orange accent-3",
  DeepOrangeAccent4 = "deep-orange accent-4",
  DeepOrangeDarken1 = "deep-orange darken-1",
  DeepOrangeDarken2 = "deep-orange darken-2",
  DeepOrangeDarken3 = "deep-orange darken-3",
  DeepOrangeDarken4 = "deep-orange darken-4",
  DeepOrangeLighten1 = "deep-orange lighten-1",
  DeepOrangeLighten2 = "deep-orange lighten-2",
  DeepOrangeLighten3 = "deep-orange lighten-3",
  DeepOrangeLighten4 = "deep-orange lighten-4",
  DeepOrangeLighten5 = "deep-orange lighten-5",
  // Material - Brown
  Brown = "brown",
  BrownDarken1 = "brown darken-1",
  BrownDarken2 = "brown darken-2",
  BrownDarken3 = "brown darken-3",
  BrownDarken4 = "brown darken-4",
  BrownLighten1 = "brown lighten-1",
  BrownLighten2 = "brown lighten-2",
  BrownLighten3 = "brown lighten-3",
  BrownLighten4 = "brown lighten-4",
  BrownLighten5 = "brown lighten-5",
  // Material - Blue Grey
  BlueGrey = "blue-grey",
  BlueGreyDarken1 = "blue-grey darken-1",
  BlueGreyDarken2 = "blue-grey darken-2",
  BlueGreyDarken3 = "blue-grey darken-3",
  BlueGreyDarken4 = "blue-grey darken-4",
  BlueGreyLighten1 = "blue-grey lighten-1",
  BlueGreyLighten2 = "blue-grey lighten-2",
  BlueGreyLighten3 = "blue-grey lighten-3",
  BlueGreyLighten4 = "blue-grey lighten-4",
  BlueGreyLighten5 = "blue-grey lighten-5",
  // Material - Grey
  Grey = "grey",
  GreyDarken1 = "grey darken-1",
  GreyDarken2 = "grey darken-2",
  GreyDarken3 = "grey darken-3",
  GreyDarken4 = "grey darken-4",
  GreyLighten1 = "grey lighten-1",
  GreyLighten2 = "grey lighten-2",
  GreyLighten3 = "grey lighten-3",
  GreyLighten4 = "grey lighten-4",
  GreyLighten5 = "grey lighten-5",
  // Material - Shades
  Black = "black",
  White = "white",
  Transparent = "transparent",
}

export enum ColorHex {
  // Vuetify Theme
  Primary = "#1976D2",
  Secondary = "#424242",
  Accent = "#82B1FF",
  Error = "#FF5252",
  Info = "#2196F3",
  Success = "#4CAF50",
  Warning = "#FB8C00",
  // Material - Red
  Red = "#F44336",
  RedAccent1 = "#FF8A80",
  RedAccent2 = "#FF5252",
  RedAccent3 = "#FF1744",
  RedAccent4 = "#D50000",
  RedDarken1 = "#E53935",
  RedDarken2 = "#D32F2F",
  RedDarken3 = "#C62828",
  RedDarken4 = "#B71C1C",
  RedLighten1 = "#EF5350",
  RedLighten2 = "#E57373",
  RedLighten3 = "#EF9A9A",
  RedLighten4 = "#FFCDD2",
  RedLighten5 = "#FFEBEE",
  // Material - Pink
  Pink = "#E91E63",
  PinkAccent1 = "#FF80AB",
  PinkAccent2 = "#FF4081",
  PinkAccent3 = "#F50057",
  PinkAccent4 = "#C51162",
  PinkDarken1 = "#D81B60",
  PinkDarken2 = "#C2185B",
  PinkDarken3 = "#AD1457",
  PinkDarken4 = "#880E4F",
  PinkLighten1 = "#EC407A",
  PinkLighten2 = "#F06292",
  PinkLighten3 = "#F48FB1",
  PinkLighten4 = "#F8BBD0",
  PinkLighten5 = "#FCE4EC",
  // Material - Purple
  Purple = "#9C27B0",
  PurpleAccent1 = "#EA80FC",
  PurpleAccent2 = "#E040FB",
  PurpleAccent3 = "#D500F9",
  PurpleAccent4 = "#AA00FF",
  PurpleDarken1 = "#8E24AA",
  PurpleDarken2 = "#7B1FA2",
  PurpleDarken3 = "#6A1B9A",
  PurpleDarken4 = "#4A148C",
  PurpleLighten1 = "#AB47BC",
  PurpleLighten2 = "#BA68C8",
  PurpleLighten3 = "#CE93D8",
  PurpleLighten4 = "#E1BEE7",
  PurpleLighten5 = "#F3E5F5",
  // Material - Deep Purple
  DeepPurple = "#673AB7",
  DeepPurpleAccent1 = "#B388FF",
  DeepPurpleAccent2 = "#7C4DFF",
  DeepPurpleAccent3 = "#651FFF",
  DeepPurpleAccent4 = "#6200EA",
  DeepPurpleDarken1 = "#5E35B1",
  DeepPurpleDarken2 = "#512DA8",
  DeepPurpleDarken3 = "#4527A0",
  DeepPurpleDarken4 = "#311B92",
  DeepPurpleLighten1 = "#7E57C2",
  DeepPurpleLighten2 = "#9575CD",
  DeepPurpleLighten3 = "#B39DDB",
  DeepPurpleLighten4 = "#D1C4E9",
  DeepPurpleLighten5 = "#EDE7F6",
  // Material - Indigo
  Indigo = "#3F51B5",
  IndigoAccent1 = "#8C9EFF",
  IndigoAccent2 = "#536DFE",
  IndigoAccent3 = "#3D5AFE",
  IndigoAccent4 = "#304FFE",
  IndigoDarken1 = "#3949AB",
  IndigoDarken2 = "#303F9F",
  IndigoDarken3 = "#283593",
  IndigoDarken4 = "#1A237E",
  IndigoLighten1 = "#5C6BC0",
  IndigoLighten2 = "#7986CB",
  IndigoLighten3 = "#9FA8DA",
  IndigoLighten4 = "#C5CAE9",
  IndigoLighten5 = "#E8EAF6",
  // Material - Blue
  Blue = "#2196F3",
  BlueAccent1 = "#82B1FF",
  BlueAccent2 = "#448AFF",
  BlueAccent3 = "#2979FF",
  BlueAccent4 = "#2962FF",
  BlueDarken1 = "#1E88E5",
  BlueDarken2 = "#1976D2",
  BlueDarken3 = "#1565C0",
  BlueDarken4 = "#0D47A1",
  BlueLighten1 = "#42A5F5",
  BlueLighten2 = "#64B5F6",
  BlueLighten3 = "#90CAF9",
  BlueLighten4 = "#BBDEFB",
  BlueLighten5 = "#E3F2FD",
  // Material - Light Blue
  LightBlue = "#03A9F4",
  LightBlueAccent1 = "#80D8FF",
  LightBlueAccent2 = "#40C4FF",
  LightBlueAccent3 = "#00B0FF",
  LightBlueAccent4 = "#0091EA",
  LightBlueDarken1 = "#039BE5",
  LightBlueDarken2 = "#0288D1",
  LightBlueDarken3 = "#0277BD",
  LightBlueDarken4 = "#01579B",
  LightBlueLighten1 = "#29B6F6",
  LightBlueLighten2 = "#4FC3F7",
  LightBlueLighten3 = "#81D4FA",
  LightBlueLighten4 = "#B3E5FC",
  LightBlueLighten5 = "#E1F5FE",
  // Material - Cyan
  Cyan = "#00BCD4",
  CyanAccent1 = "#84FFFF",
  CyanAccent2 = "#18FFFF",
  CyanAccent3 = "#00E5FF",
  CyanAccent4 = "#00B8D4",
  CyanDarken1 = "#00ACC1",
  CyanDarken2 = "#0097A7",
  CyanDarken3 = "#00838F",
  CyanDarken4 = "#006064",
  CyanLighten1 = "#26C6DA",
  CyanLighten2 = "#4DD0E1",
  CyanLighten3 = "#80DEEA",
  CyanLighten4 = "#B2EBF2",
  CyanLighten5 = "#E0F7FA",
  // Material - Teal
  Teal = "#009688",
  TealAccent1 = "#A7FFEB",
  TealAccent2 = "#64FFDA",
  TealAccent3 = "#1DE9B6",
  TealAccent4 = "#1DE9B6",
  TealDarken1 = "#00897B",
  TealDarken2 = "#00796B",
  TealDarken3 = "#00695C",
  TealDarken4 = "#004D40",
  TealLighten1 = "#26A69A",
  TealLighten2 = "#4DB6AC",
  TealLighten3 = "#80CBC4",
  TealLighten4 = "#B2DFDB",
  TealLighten5 = "#E0F2F1",
  // Material - Green
  Green = "#4CAF50",
  GreenAccent1 = "#B9F6CA",
  GreenAccent2 = "#69F0AE",
  GreenAccent3 = "#00E676",
  GreenAccent4 = "#00C853",
  GreenDarken1 = "#43A047",
  GreenDarken2 = "#388E3C",
  GreenDarken3 = "#2E7D32",
  GreenDarken4 = "#1B5E20",
  GreenLighten1 = "#66BB6A",
  GreenLighten2 = "#81C784",
  GreenLighten3 = "#A5D6A7",
  GreenLighten4 = "#C8E6C9",
  GreenLighten5 = "#E8F5E9",
  // Material - Light Green
  LightGreen = "#8BC34A",
  LightGreenAccent1 = "#CCFF90",
  LightGreenAccent2 = "#B2FF59",
  LightGreenAccent3 = "#76FF03",
  LightGreenAccent4 = "#64DD17",
  LightGreenDarken1 = "#7CB342",
  LightGreenDarken2 = "#689F38",
  LightGreenDarken3 = "#558B2F",
  LightGreenDarken4 = "#33691E",
  LightGreenLighten1 = "#9CCC65",
  LightGreenLighten2 = "#AED581",
  LightGreenLighten3 = "#C5E1A5",
  LightGreenLighten4 = "#DCEDC8",
  LightGreenLighten5 = "#F1F8E9",
  // Material - Lime
  Lime = "#CDDC39",
  LimeAccent1 = "#F4FF81",
  LimeAccent2 = "#EEFF41",
  LimeAccent3 = "#C6FF00",
  LimeAccent4 = "#AEEA00",
  LimeDarken1 = "#C0CA33",
  LimeDarken2 = "#AFB42B",
  LimeDarken3 = "#9E9D24",
  LimeDarken4 = "#827717",
  LimeLighten1 = "#D4E157",
  LimeLighten2 = "#DCE775",
  LimeLighten3 = "#E6EE9C",
  LimeLighten4 = "#F0F4C3",
  LimeLighten5 = "#F9FBE7",
  // Material - Yellow
  Yellow = "#FFEB3B",
  YellowAccent1 = "#FFFF8D",
  YellowAccent2 = "#FFFF00",
  YellowAccent3 = "#FFEA00",
  YellowAccent4 = "#FFD600",
  YellowDarken1 = "#FDD835",
  YellowDarken2 = "#FBC02D",
  YellowDarken3 = "#F9A825",
  YellowDarken4 = "#F57F17",
  YellowLighten1 = "#FFEE58",
  YellowLighten2 = "#FFF176",
  YellowLighten3 = "#FFF59D",
  YellowLighten4 = "#FFF9C4",
  YellowLighten5 = "#FFFDE7",
  // Material - Amber
  Amber = "#FFC107",
  AmberAccent1 = "#FFE57F",
  AmberAccent2 = "#FFD740",
  AmberAccent3 = "#FFC400",
  AmberAccent4 = "#FFAB00",
  AmberDarken1 = "#FFB300",
  AmberDarken2 = "#FFA000",
  AmberDarken3 = "#FF8F00",
  AmberDarken4 = "#FF6F00",
  AmberLighten1 = "#FFCA28",
  AmberLighten2 = "#FFD54F",
  AmberLighten3 = "#FFE082",
  AmberLighten4 = "#FFECB3",
  AmberLighten5 = "#FFF8E1",
  // Material - Orange
  Orange = "#FF9800",
  OrangeAccent1 = "#FFD180",
  OrangeAccent2 = "#FFAB40",
  OrangeAccent3 = "#FF9100",
  OrangeAccent4 = "#FF6D00",
  OrangeDarken1 = "#FB8C00",
  OrangeDarken2 = "#F57C00",
  OrangeDarken3 = "#EF6C00",
  OrangeDarken4 = "#E65100",
  OrangeLighten1 = "#FFA726",
  OrangeLighten2 = "#FFB74D",
  OrangeLighten3 = "#FFCC80",
  OrangeLighten4 = "#FFE0B2",
  OrangeLighten5 = "#FFF3E0",
  // Material - Deep Orange
  DeepOrange = "#FF5722",
  DeepOrangeAccent1 = "#FF9E80",
  DeepOrangeAccent2 = "#FF6E40",
  DeepOrangeAccent3 = "#FF3D00",
  DeepOrangeAccent4 = "#DD2C00",
  DeepOrangeDarken1 = "#F4511E",
  DeepOrangeDarken2 = "#E64A19",
  DeepOrangeDarken3 = "#D84315",
  DeepOrangeDarken4 = "#BF360C",
  DeepOrangeLighten1 = "#FF7043",
  DeepOrangeLighten2 = "#FF8A65",
  DeepOrangeLighten3 = "#FFAB91",
  DeepOrangeLighten4 = "#FFCCBC",
  DeepOrangeLighten5 = "#FBE9E7",
  // Material - Brown
  Brown = "#795548",
  BrownDarken1 = "#6D4C41",
  BrownDarken2 = "#5D4037",
  BrownDarken3 = "#4E342E",
  BrownDarken4 = "#3E2723",
  BrownLighten1 = "#8D6E63",
  BrownLighten2 = "#A1887F",
  BrownLighten3 = "#BCAAA4",
  BrownLighten4 = "#D7CCC8",
  BrownLighten5 = "#EFEBE9",
  // Material - Blue Grey
  BlueGrey = "#607D8B",
  BlueGreyDarken1 = "#546E7A",
  BlueGreyDarken2 = "#455A64",
  BlueGreyDarken3 = "#37474F",
  BlueGreyDarken4 = "#263238",
  BlueGreyLighten1 = "#78909C",
  BlueGreyLighten2 = "#90A4AE",
  BlueGreyLighten3 = "#B0BEC5",
  BlueGreyLighten4 = "#CFD8DC",
  BlueGreyLighten5 = "#ECEFF1",
  // Material - Grey
  Grey = "#9E9E9E",
  GreyDarken1 = "#757575",
  GreyDarken2 = "#616161",
  GreyDarken3 = "#424242",
  GreyDarken4 = "#212121",
  GreyLighten1 = "#BDBDBD",
  GreyLighten2 = "#E0E0E0",
  GreyLighten3 = "#EEEEEE",
  GreyLighten4 = "#F5F5F5",
  GreyLighten5 = "#FAFAFA",
  // Material - Shades
  Black = "#000000",
  White = "#FFFFFF",
  Transparent = "transparent",
}
