import { registerModule } from "@/plugins/Store";
import { Color } from "@/types/Enums/Color";
import { escape } from "lodash";
import {
  Actions,
  Getters,
  Module as VuexModule,
  Mutations,
} from "vuex-smart-module";
import "../state";
import { Message } from "./types";

class ModuleState {
  messages: Required<Message>[] = [];
}

class ModuleGetters extends Getters<ModuleState> {}

class ModuleMutations extends Mutations<ModuleState> {
  addMessage(message: Message) {
    this.state.messages.push({
      color: message.color ?? Color.Error,
      timeout: message.timeout ?? 5000,
      // Prevent XSS attacks by escaping the text. But allow <br> tags.
      text: escape(message.text).replace("&lt;br&gt;", "<br>"),
    });
  }

  removeFirstMessage() {
    this.state.messages.shift();
  }
}

class ModuleActions extends Actions<
  ModuleState,
  ModuleGetters,
  ModuleMutations,
  ModuleActions
> {
  async addMessage(message: string | Message) {
    if (typeof message === "string") {
      this.commit("addMessage", { text: message });
    } else {
      this.commit("addMessage", message);
    }
  }

  async removeMessage(message: Message) {
    if (JSON.stringify(message) === JSON.stringify(this.state.messages[0])) {
      return new Promise<void>((resolve) => {
        // Wait for 200ms to give the snackbar enough time to fade out.
        setTimeout(() => {
          this.commit("removeFirstMessage");
          resolve();
        }, 200);
      });
    }
  }
}

const Module = new VuexModule({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const UiSnackbarContext = registerModule("Ui/Snackbar", Module);
