import { Brand } from "../Brand";

/** UUID. */
export type Uuid = Brand<string, "UUID">;

/** UUID RegExp. */
export const uuidRegExp =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

/**
 * Type guard for UUID.
 *
 * @param value Value to test.
 */
export const isUuid = (value: unknown): value is Uuid => {
  return typeof value === "string" && uuidRegExp.test(value);
};
