import { isAsyncFunction } from "@/lib/Helpers";
import { inObject, isObject } from "@/types/TypeGuard";
import { Component, Vue } from "vue-property-decorator";

export class FormValidationError extends Error {
  constructor() {
    super("Form validation failed.");
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Mixin for handling resets and validation of form fields and form related components.
 *
 * @remarks
 * Include this mixin if your component uses form fields or other
 * form related components that need to be reset or validated.
 */
@Component
export class FormMixin extends Vue {
  /**
   * Resets the form.
   *
   * @remarks
   * This method should be overwritten inside components when custom behavior
   * is needed.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async formReset(..._arguments: unknown[]): Promise<void> {
    await this.resetForm();
  }

  /**
   * Validates the form.
   *
   * @remarks
   * This method should be overwritten inside components when custom behavior
   * is needed.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async formValidate(..._arguments: unknown[]): Promise<void> {
    await this.validateForm();
  }

  /**
   * Resets any and all form related components.
   *
   * @remarks
   * Only call this directly from inside your components `formReset` method.
   *
   * @param reset A callback for custom reset behavior.
   */
  async resetForm(reset?: () => Promise<void>): Promise<void> {
    // Reset vuelidate.
    const vuelidate = new Promise<void>((resolve) => {
      if (this.$v) {
        this.$v.$reset();
        this.waitForPending().then(() => resolve());
      } else {
        resolve();
      }
    });

    // Reset promises for references.
    const refs: Promise<void>[] = [];

    // Loop through the references.
    for (const key of Object.keys(this.$refs)) {
      const ref = this.$refs[key];
      if (isObject(ref) && inObject("formReset", ref, isAsyncFunction)) {
        refs.push(ref.formReset());
      }
    }

    // Await the reset of the component and all references.
    await Promise.all([vuelidate, ...refs]);

    // Then await the callback.
    if (reset) {
      await reset();
    }
  }

  /**
   * Validates any and all form related components.
   *
   * @remarks
   * Only call this directly from inside your components `formValidate` method.
   *
   * @param validate A callback for custom validation behavior.
   */
  async validateForm(validate?: () => Promise<void>): Promise<void> {
    // Validate promise.
    const vuelidate = new Promise<void>((resolve, reject) => {
      if (this.$v) {
        this.$v.$touch();
        this.waitForPending().then(() => {
          if (this.$v.$invalid) {
            reject(new FormValidationError());
          } else {
            resolve();
          }
        });
      } else {
        resolve();
      }
    });

    // Reset promises for references.
    const refs: Promise<void>[] = [];

    // Loop through the references.
    for (const key of Object.keys(this.$refs)) {
      const ref = this.$refs[key];

      if (isObject(ref) && inObject("formValidate", ref, isAsyncFunction)) {
        refs.push(ref.formValidate());
      }
    }

    // Await the validation of the component and all references.
    await Promise.all([vuelidate, ...refs]);

    // Then await the callback.
    if (validate) {
      await validate();
    }
  }

  /** Waits for the pending flag in `vuelidate` to be `false`. */
  async waitForPending(): Promise<void> {
    // Don't do anything if `$v` isn't set or pending is `false`.
    if (!this.$v || !this.$v.$pending) {
      return;
    }

    // Wait for pending to be `false`.
    await new Promise<void>((resolve) => {
      const unwatch = this.$watch(
        () => !this.$v.$pending,
        (isNotPending) => {
          if (isNotPending) {
            unwatch();
            resolve();
          }
        },
        { immediate: true }
      );
    });
  }
}
