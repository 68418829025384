import { UiSnackbarContext } from "@/components/Ui/Snackbar/state";
import { Api } from "@/lib/Api";
import { ErrorMixin } from "@/mixins/ErrorMixin";
import { FormMixin } from "@/mixins/FormMixin";
import { Color } from "@/types/Enums/Color";
import { HttpMethod } from "@/types/Enums/HttpMethod";
import { HttpStatusCode } from "@/types/Enums/HttpStatusCode";
import { Component, Mixins } from "vue-property-decorator";
import { errorMessages, validations } from "./validations";

@Component({
  computed: { ...errorMessages },
  validations,
})
export class ResetPasswordForm extends Mixins(ErrorMixin, FormMixin) {
  /* #region Hooks
     #endregion */

  //#region Variables

  /** Component Loading Flag. */
  get loading(): boolean {
    return this.resetLoading;
  }

  /** Recovery Hash. (Validated in before enter hook in the router.) */
  get recoveryHash(): string {
    return this.$route.query.recoveryHash as string;
  }

  /** New Password. */
  newPassword: string | null = null;

  /** New Password Repeated. */
  newPasswordRepeated: string | null = null;

  /** Reset Loading Flag */
  resetLoading = false;

  /** Show SignIn Button Flag. */
  showSignInButton = false;

  //#endregion

  //#region Methods

  async resetPassword(
    newPassword: string,
    newPasswordRepeated: string
  ): Promise<void> {
    try {
      // Enable loading flag.
      this.resetLoading = true;

      // Reset the password.
      await this.rpcResetPassword(
        this.recoveryHash,
        newPassword,
        newPasswordRepeated
      );

      // Disable loading flag.
      this.resetLoading = false;
    } catch (error: unknown) {
      // Disable loading flag.
      this.resetLoading = false;

      this.displayError(error);
    }
  }

  //#endregion

  //#region API

  async rpcResetPassword(
    recoveryHash: string,
    password: string,
    repeatPassword: string
  ): Promise<void> {
    // Get API response.
    const response = await Api("rpc/user/reset-password", HttpMethod.POST, {
      data: {
        recoveryHash,
        password,
        repeatPassword,
      },
    });

    // Check if the response is valid.
    if (response.code === HttpStatusCode.OK) {
      return;
    }

    // API response was invalid.
    throw new Error("Invalid response: " + JSON.stringify(response, null, 2));
  }

  //#endregion

  //#region Form

  /** Form Reset. */
  async formReset(): Promise<void> {
    const reset = async () => {
      this.newPassword = null;
      this.newPasswordRepeated = null;
    };

    this.resetForm(reset);
  }

  /** Form Submit */
  async formSubmit(): Promise<void> {
    try {
      await this.formValidate();

      if (this.newPassword && this.newPassword === this.newPasswordRepeated) {
        await this.resetPassword(this.newPassword, this.newPasswordRepeated);

        // Add a created message to the snackbar queue.
        UiSnackbarContext.actions.addMessage({
          color: Color.Success,
          text: "Ditt lösenord har återställts.",
        });

        this.showSignInButton = true;
      }
    } catch (error: unknown) {
      this.displayError(error);
    }
  }

  //#endregion
}
