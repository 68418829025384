import { UserContext } from "@/plugins/Auth/state";
import { uuidRegExp } from "@/types/Brands/Uuid";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const uuid = uuidRegExp.toString().slice(2, -3);

export const AdminRoutes: RouteConfig[] = [
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "Ui" */ "@/components/Ui/component.vue"),
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      if (UserContext.state.user) {
        if (UserContext.getters.isAdmin) {
          next();
        } else {
          next({ name: "CatchAll", params: { 0: to.fullPath } });
        }
      } else {
        sessionStorage.setItem("return-url", to.fullPath);
        next({ name: "App" });
      }
    },
    children: [
      // Profile
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "Admin.Profile" */ "@/components/View/Admin/Profile/View.vue"
          ),
        children: [
          {
            name: "Admin.Profile",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Admin.Profile" */ "@/components/View/Admin/Profile/FormView.vue"
              ),
          },
        ],
      },
      // Agencies
      {
        path: "agencies",
        component: () =>
          import(
            /* webpackChunkName: "Admin.Agency" */ "@/components/View/Admin/Agency/View.vue"
          ),
        children: [
          {
            name: "Admin.Agencies",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Admin.Agency" */ "@/components/View/Admin/Agency/TableView.vue"
              ),
          },
          {
            name: "Admin.AddAgency",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Admin.Agency" */ "@/components/View/Admin/Agency/FormView.vue"
              ),
          },
          {
            name: "Admin.EditAgency",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Admin.Agency" */ "@/components/View/Admin/Agency/FormView.vue"
              ),
          },
        ],
      },
      // Courses
      {
        path: "courses",
        component: () =>
          import(
            /* webpackChunkName: "Admin.Course" */ "@/components/View/Admin/Course/View.vue"
          ),
        children: [
          {
            name: "Admin.Courses",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Admin.Course" */ "@/components/View/Admin/Course/FormView.vue"
              ),
          },
        ],
      },
      // Languages
      {
        path: "languages",
        component: () =>
          import(
            /* webpackChunkName: "Admin.Language" */ "@/components/View/Admin/Language/View.vue"
          ),
        children: [
          {
            name: "Admin.Languages",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Admin.Language" */ "@/components/View/Admin/Language/FormView.vue"
              ),
          },
        ],
      },
      // RegionsOfOrigin
      {
        path: "regions-of-origin",
        component: () =>
          import(
            /* webpackChunkName: "Admin.RegionOfOrigin" */ "@/components/View/Admin/RegionOfOrigin/View.vue"
          ),
        children: [
          {
            name: "Admin.RegionsOfOrigin",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Admin.RegionOfOrigin" */ "@/components/View/Admin/RegionOfOrigin/FormView.vue"
              ),
          },
        ],
      },
      // News Articles
      {
        path: "news",
        component: () =>
          import(
            /* webpackChunkName: "Admin.News" */ "@/components/View/Admin/News/View.vue"
          ),
        children: [
          {
            name: "Admin.NewsArticles",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Admin.News" */ "@/components/View/Admin/News/TableView.vue"
              ),
          },
          {
            name: "Admin.AddNewsArticle",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Admin.News" */ "@/components/View/Admin/News/FormView.vue"
              ),
          },
          {
            name: "Admin.EditNewsArticle",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Admin.News" */ "@/components/View/Admin/News/FormView.vue"
              ),
          },
        ],
      },
    ],
  },
];
