import { escape } from "lodash";
import { ifInObject, inObject, isObject } from "./TypeGuard";

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  isAlternate?: false;
  postalCode: string;
}

export const isAddress = (value: unknown): value is Address => {
  return (
    isObject(value) &&
    inObject("addressLine1", value, "string") &&
    ifInObject("addressLine2", value, "string") &&
    ifInObject("addressLine3", value, "string") &&
    inObject("city", value, "string") &&
    ifInObject(
      "isAlternate",
      value,
      (value: unknown): value is false => value === false
    ) &&
    inObject("postalCode", value, "string")
  );
};

export interface AlternateAddress
  extends Omit<Address, "isAlternate" | "postalCode">,
    Partial<Pick<Address, "postalCode">> {
  isAlternate: true;
}

export const isAlternateAddress = (value: unknown): value is Address => {
  return (
    isObject(value) &&
    inObject("addressLine1", value, "string") &&
    ifInObject("addressLine2", value, "string") &&
    ifInObject("addressLine3", value, "string") &&
    inObject("city", value, "string") &&
    inObject(
      "isAlternate",
      value,
      (value: unknown): value is true => value === true
    ) &&
    ifInObject("postalCode", value, "string")
  );
};

export type AnyAddress = Address | AlternateAddress;

export const isAnyAddress = (value: unknown): value is Address => {
  return (
    isObject(value) &&
    inObject("addressLine1", value, "string") &&
    ifInObject("addressLine2", value, "string") &&
    ifInObject("addressLine3", value, "string") &&
    inObject("city", value, "string") &&
    ifInObject("isAlternate", value, "boolean") &&
    ((value.isAlternate === true &&
      ifInObject("postalCode", value, "string")) ||
      inObject("postalCode", value, "string"))
  );
};

/**
 * Format a address value object.
 *
 * @param address - Address value object.
 *
 * @return Formatted string.
 */
export const formatAddress = (address?: AnyAddress | undefined): string => {
  if (!address) {
    return "";
  }

  let formatted = "";

  for (let i = 1; i <= 3; i++) {
    if ("addressLine" + i in address) {
      const key = ("addressLine" + i) as keyof Address;
      const line = address[key];

      if (typeof line === "string" && line.length > 0) {
        if (i > 1 && formatted.length > 0) {
          formatted += ", ";
        }

        formatted += line;
      }
    }
  }

  if (address.postalCode || address.city) {
    formatted += ", ";
  }

  if (address.postalCode) {
    formatted += address.postalCode;
  }

  if (address.city) {
    if (address.postalCode) {
      formatted += " ";
    }

    formatted += address.city;
  }

  // Escape HTML.
  formatted = escape(formatted);

  return formatted;
};
