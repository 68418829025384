



































import { UiSnackbar } from "./component";

export default UiSnackbar;
