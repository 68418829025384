import { sleep } from "@/lib/Helpers";
import { Color } from "@/types/Enums/Color";
import { Component, Vue, Watch } from "vue-property-decorator";
import { UiSnackbarContext } from "./state";
import { Message } from "./types";

@Component
export class UiSnackbar extends Vue {
  //#region Variables

  /** Show Message flag */
  showMessage = false;

  /** The message being show. */
  message: Message = {
    color: Color.Error,
    text: "",
    timeout: 5000,
  };

  /** Timeout animation value. */
  timeout = 100;

  /** Timeout animation interval. */
  timeoutInterval = 0;

  /** Next message in queue. */
  get nextMessage(): Required<Message> | undefined {
    return UiSnackbarContext.state.messages[0];
  }

  //#endregion

  //#region Methods

  /** Remove the message from the queue. */
  onMessageClose(): void {
    UiSnackbarContext.actions.removeMessage(this.message);
  }

  //#endregion

  //#region Watchers

  /**
   * Show the next message.
   *
   * @param message The message to show.
   */
  @Watch("nextMessage")
  onNextMessageChange(message?: Required<Message>): void {
    if (message) {
      this.message = message;
      this.showMessage = true;
    }
  }

  /**
   * Starts and clear timeout intervals.
   *
   * @param show Whether a message is shown.
   */
  @Watch("showMessage")
  onShowMessageChange(show: boolean): void {
    if (show && this.message.timeout && this.message.timeout > 1500) {
      const messageTimeout = this.message.timeout;
      const tick = 1000; // Can't be lower than 1000ms.

      const ticker = () => {
        if (this.timeout > 0) {
          this.timeout = this.timeout - (tick / messageTimeout) * 100;
        } else {
          window.clearInterval(this.timeoutInterval);
          this.timeoutInterval = 0;
          this.timeout = 100;
        }
      };

      /* We need to wait for 400ms to match timeout === 0
         to the snackbar fading out. */
      sleep(400).then(() => {
        ticker();
        this.timeoutInterval = window.setInterval(ticker, tick);
      });
    } else {
      window.clearInterval(this.timeoutInterval);
      this.timeoutInterval = 0;
      this.timeout = 100;
    }
  }
  //#endregion
}
