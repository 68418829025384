import { IAuthUrls } from "./types";

/**
 * Get the URLs required by the OIDC Client.
 */
export const getAuthUrls = async function (): Promise<IAuthUrls> {
  /** Regex for localhost and all loopback ip-addresses. */
  const localhostRegex =
    /^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*:)*?:?0*1$/i;

  /** Local storage key for development ID server url. */
  const devIdServerUrlKey = "DEV_id-server-url";

  /** ID server URLs. */
  const urls: Omit<IAuthUrls, "authority"> = {
    signInUrl: new URL(`${location.protocol}//${location.host}/sign-in`),
    signOutUrl: new URL(`${location.protocol}//${location.host}/sign-out`),
    silentUrl: new URL(`${location.protocol}//${location.host}/silent`),
  };

  if (localhostRegex.test(location.hostname)) {
    // Get Dev Server URL.
    const devServerUrl = localStorage.getItem(devIdServerUrlKey);

    if (typeof devServerUrl === "string" && devServerUrl.length > 0) {
      /** If the url is in local storage we will use it. */
      return { authority: new URL(devServerUrl), ...urls };
    }

    let authority: URL;

    /**
     * For localhost we need to check if a local identity server is running.
     * If it is, we use it. Otherwise we use the edge id server.
     */
    try {
      await fetch(
        `https://${location.hostname}:44348/.well-known/openid-configuration`
      );
      authority = new URL(`https://${location.hostname}:44348`);
    } catch (error: unknown) {
      authority = new URL("https://id-edge-taqs.oadata.se");
    }

    // Save Dev Server URL.
    localStorage.setItem(devIdServerUrlKey, authority.toString());

    return { authority, ...urls };
  } else if (/\..+\..+/.test(location.hostname)) {
    /** For subdomains we use a sibling subdomain with the prefix "id-". */
    return { authority: new URL(`https://id-${location.host}`), ...urls };
  }

  /** On domains we use the "id" subdomain. */
  return { authority: new URL(`https://id.${location.host}`), ...urls };
};
