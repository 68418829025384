import { isUuid, Uuid } from "@/types/Brands/Uuid";
import { ifInObject, inObject, isObject } from "@/types/TypeGuard";

/** Auth URLs. */
export interface IAuthUrls {
  authority: URL;
  signInUrl: URL;
  signOutUrl: URL;
  silentUrl: URL;
}

/** User Types. */
export enum UserType {
  Admin = "admin",
  Agent = "agent",
  Customer = "customer",
  Resource = "resource",
}

/** User Roles. */
export enum UserRole {
  SysAdmin = "SysAdmin",
  // Agent:
  Agent = "Agent",
  Administrator = "Administrator",
  // Agent roles:
  AssignmentsReadWrite = "AssignmentsReadWrite",
  BillingsReadOnly = "BillingsReadOnly",
  BillingsReadWrite = "BillingsReadWrite",
  ContractsReadOnly = "ContractsReadOnly",
  ContractsReadWrite = "ContractsReadWrite",
  CustomersReadOnly = "CustomersReadOnly",
  CustomersReadWrite = "CustomersReadWrite",
  LanguagesReadOnly = "LanguagesReadOnly",
  LanguagesReadWrite = "LanguagesReadWrite",
  NewsReadOnly = "NewsReadOnly",
  NewsReadWrite = "NewsReadWrite",
  ResourcesReadOnly = "ResourcesReadOnly",
  ResourcesReadWrite = "ResourcesReadWrite",
  SettingsReadOnly = "SettingsReadOnly",
  SettingsReadWrite = "SettingsReadWrite",
  StatisticsReadOnly = "StatisticsReadOnly",
  UsersReadOnly = "UsersReadOnly",
  UsersReadWrite = "UsersReadWrite",
  // Customer:
  Customer = "Customer",
  CustomerAdmin = "CustomerAdmin",
  // Resource:
  Resource = "Resource",
  Interpreter = "Translator",
  Contractor = "Contractor",
}

/** User Roles that can be used for write permissions. */
export type WriteRole = Extract<
  UserRole,
  UserRole.Agent | `${string}ReadWrite`
>;

/** Base interface for User. */
export interface User {
  accessToken: string;
  expiresAt: number;
  familyName: string;
  givenName: string;
  id: Uuid;
  ids: Record<string, Uuid>;
  roles: UserRole[];
  tokenType: string;
}

/** Value is User. */
export const isUser = (value: unknown): value is User => {
  return (
    isObject(value) &&
    inObject("accessToken", value, "string") &&
    inObject("expiresAt", value, "number") &&
    ifInObject("familyName", value, "string") &&
    inObject("givenName", value, "string") &&
    inObject("id", value, isUuid) &&
    inObject("tokenType", value, "string")
  );
};
